import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { Router, ActivatedRoute } from '@angular/router';
declare var $;

@Component({
  selector: 'app-itemadd',
  templateUrl: './itemadd.component.html',
  styleUrls: ['./itemadd.component.scss']
})
export class ItemaddComponent implements OnInit {
  pdata: any;
  constructor(
    private ds: DataService,
    private router: Router,
    private route: ActivatedRoute
    ) {}

  ngOnInit(): void {
    const pid = this.route.snapshot.params.id;

    if(pid){
      this.ds.dsGet('product/'+pid,'{}').subscribe((data)=>{
        this.pdata = JSON.parse(JSON.stringify(data)).hdr;
        $('#save').html('Update');
        $('#tt').html('Update Item');
      });
    }else{
      console.log('m>add');
    }
  }


  saveProduct(){


    var fdata = {};
    $('#items-form').serializeArray().map(function(x){fdata[x.name] = x.value;}); 

    this.ds.dsPost('product/add',fdata).subscribe((data)=>{ 
      // this.router.navigateByUrl('/app/items/'+data.lid);
      console.log('new item created '+data.lid);
      this.router.navigateByUrl('/app/items');
    });


  }

}



