import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.scss']
})
export class InventoryComponent implements OnInit {


  isummary;
  
  constructor(
    private ds: DataService
  ) { }

  ngOnInit(): void {
    this.ds.dsGet('inventory/summary','{}').subscribe((data)=>{
      this.isummary = JSON.parse(JSON.stringify(data)).items;
    });

  }

}
