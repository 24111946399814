import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { ActivatedRoute } from '@angular/router';

declare var $;


@Component({
  selector: 'app-sodetail',
  templateUrl: './sodetail.component.html',
  styleUrls: ['./sodetail.component.scss']
})
export class SodetailComponent implements OnInit {


  constructor(
    private ds: DataService,
    private route: ActivatedRoute

  ) { }

  shipments:any;
  dtl:any;
  hdr:any;
  dItems:any;
  dItemsf=0;

  ngOnInit(): void {
    const pid = this.route.snapshot.params.id;
    this.ds.dsGet('so/'+pid, '{}').subscribe((data)=>{ 
     // this.dtl = data.dtl;
     // this.hdr = data.hdr;

      this.dtl = JSON.parse(JSON.stringify(data)).dtl;
      this.hdr = JSON.parse(JSON.stringify(data)).hdr;

    });

    this.gshipment();
    this.gitems();

  }

  gshipment(){
    const pid = this.route.snapshot.params.id;
    this.ds.dsGet('so/'+pid+'/shipment', '{}').subscribe((data)=>{ 
      this.shipments = JSON.parse(JSON.stringify(data)).items;
    });
  }



  gitems(){
    if(this.dItemsf === 0 ){
      this.ds.dsGet('products',{}).subscribe((data)=>{
        // this.dItems = data.items;
        this.dItems = JSON.parse(JSON.stringify(data)).items;
       // this.dItemsf = 1;
      });
    }
  }



  modal_open(modalId){
    $('#'+modalId).addClass('show');
    this.gitems();

  }

  modal_close(modalId){
    $('#'+modalId).removeClass('show');
  }




  shipment_add(){
    var fdata = {};
    $('#new_shipment').serializeArray().map(function(x){fdata[x.name] = x.value;}); 

    if($('select[name=shipping_carrier_id]').val()==='0'){alert('select shipping carrier'); return false;}
    if($('input[name=shipping_tracking_number]').val()===''){alert('enter tracking number'); return false;}

    this.ds.dsPost('so/shipment',fdata).subscribe((data)=>{ 
      this.gshipment();
      this.modal_close('add_shipment');
    });
  }



  order_line_add(){
    var fdata = {};
    $('#newline').serializeArray().map(function(x){fdata[x.name] = x.value;}); 

    if($('select[name=item_id]').val()==='0'){alert('select item'); return false;}
    if($('select[name=item_condition]').val()==='0'){alert('select condition'); return false;}

    this.ds.dsPost('soline/add',fdata).subscribe((data)=>{ 
      this.ngOnInit();
      this.modal_close('add_order_line');
    });
  }

  order_line_delete(LineId,act){
    if (act ==='ask'){
      $('#line_to_delete').val(LineId);
      this.modal_open('delete_order_line');
    }

    if (act ==='cancel'){
      $('#line_to_delete').val(LineId);
      this.modal_close('delete_order_line');
    }

    if (act ==='ok'){
      this.ds.dsGet('soline/delete/'+$('#line_to_delete').val(),'{}').subscribe((data)=>{ 
        this.ngOnInit();
        $('#line_to_delete').val('0');

        this.modal_close('delete_order_line');
      });
    }
  }


}
