<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Inventory Movements</h1>
            </div>
            <div class="col-sm-6">
            </div>
        </div>
    </div>
</section>


<section class="content">

    <div class="row">

        <div class="col-md-12">
            <div class="box box-info ">
            <table id="example1" class="table table-bordered table-striped">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Date</th>
                        <th>Reference</th>
                        <th>Created By</th>
                        <th>Item Name</th>
                        <th>Serial Number</th>
                        <th>Location</th>
                        <th>Condition</th>
                        <th>Note</th>
                     </tr>
                </thead>
                <tbody>

                    
                    <tr>
                        <td>1</td>
                        <td>2020-04-22 16:42</td>
                        <td>Received [<a [routerLink]="['/app/po/123']">PO-123</a>]</td>
                        <td>Engin</td>
                        <td>DC34</td>
                        <td><a [routerLink]="['/app/inventory/movement/123']">Asdgjhr</a></td>
                        <td>Richardson-105</td>
                        <td>NIB</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>1</td>
                        <td>2020-04-22 16:42</td>
                        <td>Shipped [<a [routerLink]="['/app/so/456']">SO-456</a>]</td>
                        <td>Engin</td>
                        <td>DC34</td>
                        <td><a [routerLink]="['/app/inventory/movement/123']">Asdgjhr</a></td>
                        <td>Richardson-105</td>
                        <td>NIB</td>
                        <td></td>
                    </tr>
                    

                </tbody>
            </table>
            </div>
        </div>
    </div>

</section>