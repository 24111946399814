import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../../services/data.service';


declare var $;

@Component({
  selector: 'app-poadd',
  templateUrl: './poadd.component.html',
  styleUrls: ['./poadd.component.scss']
})
export class PoaddComponent implements OnInit {

  constructor(
    private router: Router,
    private ds: DataService
  ) { }

  vendorlist:any;



  ngOnInit(): void {


    $('.datepicker').datepicker({
      autoclose: true,
      format: 'yyyy-mm-dd',
    }).datepicker('setDate','now');


    this.ds.dsGet('vendor','{}').subscribe((data)=>{
      this.vendorlist = JSON.parse(JSON.stringify(data)).items;




      $('.select2').select2({
        tags: true,

        createTag: function (params) {
          var term = $.trim(params.term);

          if (term === '') {
            return null;
          }

          return {
            id: 'new_vendor|||'+term,
            text: term,
            newTag: true // add additional parameters
          }
        }



      });




    });

  }


  create(fn){

    if($('select[name=vendor_id]').val() === '0' ){alert('select vendor'); return false;}
    const fdata = {};
    $('#'+fn).serializeArray().map(function(x){fdata[x.name] = x.value;});

    this.ds.dsPost('po/add',fdata).subscribe((data)=>{
      this.router.navigateByUrl('/app/po/'+data.lid);
    });

  }


}
