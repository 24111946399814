import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { FormsModule } from '@angular/forms';

import { SellernodeRoutingModule } from './sellernode-routing.module';
import { SellernodeComponent } from './sellernode/sellernode.component';
import { LayoutModule } from '../layout/layout.module';

import { AgGridModule } from 'ag-grid-angular';
import { DashboardComponent } from './dashboard/dashboard.component';



import { AutocompleteLibModule } from 'angular-ng-autocomplete';


import { ItemComponent } from './item/item.component';
import { ItemaddComponent } from './item/itemadd/itemadd.component';

import { InventoryComponent } from './inventory/inventory.component';

import { InventorydetailComponent } from './inventory/inventorydetail/inventorydetail.component';
import { InventorymovementComponent } from './inventory/inventorymovement/inventorymovement.component';
import { InventorymovementdetailComponent } from './inventory/inventorymovementdetail/inventorymovementdetail.component';
import { PoComponent } from './po/po.component';
import { PoaddComponent } from './po/poadd/poadd.component';
import { PodetailComponent } from './po/podetail/podetail.component';
import { SoComponent } from './so/so.component';
import { SoaddComponent } from './so/soadd/soadd.component';
import { SodetailComponent } from './so/sodetail/sodetail.component';
import { VendorComponent } from './vendor/vendor.component';
import { VendoraddComponent } from './vendor/vendoradd/vendoradd.component';
import { CustomerComponent } from './customer/customer.component';
import { CustomeraddComponent } from './customer/customeradd/customeradd.component';
import { VendordetailComponent } from './vendor/vendordetail/vendordetail.component';
import { CustomerdetailComponent } from './customer/customerdetail/customerdetail.component';
import { ShippingComponent } from './shipping/shipping.component';
import { ShipinComponent } from './shipping/shipin/shipin.component';
import { ShipoutComponent } from './shipping/shipout/shipout.component';
import { ShippingdetailComponent } from './shipping/shippingdetail/shippingdetail.component';
import { UnauthorizedComponent } from './shared/unauthorized/unauthorized.component';
import { NotfoundComponent } from './shared/notfound/notfound.component';
import { KioskComponent } from './shipping/kiosk/kiosk.component';
import { RecentshipmentComponent } from './shipping/recentshipment/recentshipment.component';
import { InventorymanageComponent } from './inventory/inventorymanage/inventorymanage.component';
import { InventorymanagedetailComponent } from './inventory/inventorymanagedetail/inventorymanagedetail.component';
import { InventorytestComponent } from './inventory/inventorytest/inventorytest.component';
import { ShipinmanualComponent } from './shipping/shipinmanual/shipinmanual.component';

@NgModule({
  // tslint:disable-next-line: max-line-length
  declarations: [SellernodeComponent, DashboardComponent, ItemComponent,ItemaddComponent, InventoryComponent, InventorydetailComponent, InventorymovementComponent, InventorymovementdetailComponent, PoComponent, PoaddComponent, PodetailComponent, SoComponent, SoaddComponent, SodetailComponent, VendorComponent, VendoraddComponent, CustomerComponent, CustomeraddComponent, VendordetailComponent, CustomerdetailComponent, ShippingComponent, ShipinComponent, ShipoutComponent, ShippingdetailComponent, UnauthorizedComponent, NotfoundComponent, KioskComponent, RecentshipmentComponent,  InventorymanageComponent, InventorymanagedetailComponent, InventorytestComponent, ShipinmanualComponent],
  imports: [
    CommonModule,
    FormsModule,
    SellernodeRoutingModule,
    LayoutModule,
    AutocompleteLibModule,
    AgGridModule.withComponents([])
  ]
})
export class SellernodeModule {
}
