import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../../services/data.service';


declare var $;

@Component({
  selector: 'app-soadd',
  templateUrl: './soadd.component.html',
  styleUrls: ['./soadd.component.scss']
})
export class SoaddComponent implements OnInit {


  constructor(
    private router: Router,
    private ds: DataService
  ) { }

  customerlist:any;



  ngOnInit(): void {

    $('.datepicker').datepicker({
      autoclose: true,
      format: 'yyyy-mm-dd',
    }).datepicker('setDate','now');


    this.ds.dsGet('customer','{}').subscribe((data)=>{
      this.customerlist = JSON.parse(JSON.stringify(data)).items;
    });

  }


  create(fn){

    if($('select[name=customer_id]').val() === '0' ){alert('select customer'); return false;}
    const fdata = {};
    $('#'+fn).serializeArray().map(function(x){fdata[x.name] = x.value;});

    this.ds.dsPost('so/add',fdata).subscribe((data)=>{
      this.router.navigateByUrl('/app/so/'+data.lid);
    });

  }

}
