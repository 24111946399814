<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Sales Orders <button class="btn btn-success" (click)="ngOnInit();" id='btn_ok'> Refresh </button></h1>
            </div>
            <div class="col-sm-6">
                <a [routerLink]="['/app/so/']" routerLinkActive="router-link-active" ><button type="button" class="btn btn-success btn-flat">Open SO</button></a>
                <a [routerLink]="['/app/so']" routerLinkActive="router-link-active" ><button type="button" class="btn btn-success btn-flat">ALL SO</button></a>
                <a [routerLink]="['/app/so']" routerLinkActive="router-link-active" ><button type="button" class="btn btn-success btn-flat">My SO</button></a>
                <a [routerLink]="['/app/so/add']" routerLinkActive="router-link-active" ><button type="button" class="btn btn-success btn-flat">New SO</button></a>
            </div>
        </div>
    </div>
</section>


<section class="content">

    <div class="row">

        <div class="col-md-12">
            <div class="box box-info ">
            <table id="example1" class="table table-bordered table-striped">
                <thead>
                    <tr>
                        <th>SO #</th>
                        <th>Date</th>
                        <th>Ship by Date</th>
                        <th>Deliver by Date</th>
                        <th>Status</th>
                        <th>Reference</th>
                        <th>Sales Channel</th>
                        <th>Customer</th>
                        <th>Created By</th>
                        <th>Note</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>

                    <tr *ngFor="let s of sos">
                        <td><a routerLink="/app/so/{{ s.so_id }}">{{ s.so_id}}</a></td>
                        <td>{{ s.so_date }}</td>
                        <td>2020-05-21</td>
                        <td>2020-05-28</td>
                        <td>Open</td>
                        <td>111-13456-3135</td>
                        <td>AMZ-PXB</td>
                        <td><a routerLink="/app/customer/{{ s.customer_id }}">{{ s.customer_name}}</a></td>
                        <td>System</td>
                        <td></td>                                                
                        <td>
                            <div class="btn-group" title="View Account">
                                <a class="btn btn-primary btn-o dropdown-toggle" data-toggle="dropdown" href="#" aria-expanded="false">
                                    Action <span class="caret"></span>
                                </a>
                                <ul role="menu" class="dropdown-menu dropdown-light pull-right">
                                    <li>
                                        <a title="View Invoice" routerLink="/app/so/{{ s.so_id }}" [queryParams]="{action: 'view'}"><i class="fa fa-fw fa-eye text-blue"></i>View
                                            Sales Order</a>
                                    </li>
                                    <li>
                                        <a title="Update Record ?" routerLink="/app/so/{{ s.so_id }}" [queryParams]="{action: 'edit'}">
                                            <i class="fa fa-fw fa-edit text-blue"></i>Edit
                                        </a>
                                    </li>
                                    <li>
                                        <a title="Update Record ?" routerLink="/app/so/{{ s.so_id }}" [queryParams]="{action: 'print'}">
                                            <i class="fa fa-fw fa-print text-blue"></i>Print
                                        </a>
                                    </li>
                                    <li>
                                        <a title="Update Record ?" routerLink="/app/so/{{ s.so_id }}" [queryParams]="{action: 'return'}">
                                            <i class="fa fa-fw fa-print text-blue"></i>Create Return
                                        </a>
                                    </li>                                    
                                </ul>
                            </div>
                        </td>
                    </tr>



                </tbody>
            </table>
            </div>
        </div>
    </div>

</section>