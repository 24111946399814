<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Sales Order Detail</h1>
            </div>
            <div class="col-sm-6">

                
            </div>
        </div>
    </div>
</section>


<section class="content"><div class="row"><div class="col-md-12"><div class="box box-info ">




    

    <div class="row">
        <div class="col-md-12">
    
    
    
                <h4 class="box-title text-info">Details</h4>
                <table class="table table-hover table-bordered" style="width:100%" id="payments_table">
                   <thead>
                      <tr class="bg-gray ">
                         <th>#</th>
                         <th>Date</th>
                         <th>Customer</th>
                         <th>Reference</th>
                         <th>Created By</th>
                         <th>Status</th>
                         <th>Actions</th>                     
                      </tr>
                   </thead>
                   <tbody>
                    <tr>
                        <td>{{ hdr.so_id}}</td>
                        <td>{{ hdr.so_date}}</td>
                        <td><a routerLink="/app/customer/{{ hdr.customer_id }}">{{ hdr.customer_name}}</a></td>
                        <td>{{ hdr.reference}}</td>
                        <td>{{ hdr.created_by}}</td>
                        <td>{{ hdr.status_desc}}</td>
                        <td><i class="fas fa-edit"></i> <i class="fas fa-trash"></i> </td>
                      </tr>
                     <tr>
                      <th class="bg-gray ">Note</th>
                      <td colspan="6">{{ hdr.note}}</td>
                   </tr>                     
                    </tbody>
                </table>
    
    
            
        </div>
    </div>
    






    



<div class="row">
    <div class="col-md-12">



            <h4 class="box-title text-info">Items</h4>


            

            <div id="add_order_line" class="modal">

                <!-- Modal content -->
                <div class="modal-content">

                  <form id="newline">
                  <table class="table table-hover table-bordered" style="width:100%">
                    <tr>
                        <th class="bg-gray ">Item</th>
                        <td>

                            <select class="form-control" name="item_id" >
                                <option value="0">Select</option>
                                <option *ngFor="let dItem of dItems" [value]="dItem.product_id">{{dItem.product_name}}</option>
                            </select></td>
                      </tr>
                      <tr>
                        <th class="bg-gray ">Condition</th>
                        <td>
                            <select class="form-control" name="item_condition" >
                                <option value="0">Select</option>
                                <option value="1">NIB - New in Box</option>
                                <option value="2">NOB - New Open Box</option>
                                <option value="3">REF - Refurbished</option>
                                <option value="4">Used</option>
                              </select>

                        </td>
                      </tr>
                      <tr>
                        <th class="bg-gray ">Quantity</th>
                        <td><input class="form-control" type="text" name="item_qty" value="1"  ></td>
                      </tr>
                      <tr>
                        <th class="bg-gray ">Unit Price</th>
                        <td><input class="form-control" type="text" name="item_price" ></td>
                      </tr>                      
                      <tr>
                        <th class="bg-gray ">Tax</th>
                        <td><input class="form-control" type="text" name="item_tax" value="8.25" ></td>
                      </tr>  
                      <tr>
                        <th class="bg-gray ">Note</th>
                        <td><textarea name="item_note" class="form-control" ></textarea></td>
                      </tr>  
                                
                 </table>  
                 <div class="tar">
                    <button class="btn btn-danger " (click)="modal_close('add_order_line');" > Cancel</button>
                    <button class="btn btn-success " (click)="order_line_add();" > Add Item to Order</button>
                </div>   
                <input type="hidden" name="so_id" value="{{ hdr.so_id }}" >

            </form>

                  
                </div>

              </div>





              <div id="delete_order_line" class="modal">

                <!-- Modal content -->
                <div class="modal-content">
                    <p>
                        Are you sure ?
                    </p>
                    <input type="hidden" id="line_to_delete" value="0" >

                    <div class="tar">
                        <button class="btn btn-danger " (click)="order_line_delete(0,'ok');" > Delete SO Line </button>
                        <button class="btn btn-warning " (click)="order_line_delete(0,'cancel');" > Cancel </button>
                    </div>  

                  
                </div>

              </div>


            <table class="table table-hover table-bordered" style="width:100%" id="order_items">
               <thead>
                  <tr class="bg-gray ">
                     <th>#</th>
                     <th>Item Name</th>
                     <th>Condition</th>
                     <th>QTY</th>
                     <th>Unit Price</th>
                     <th>Tax %</th>
                     <th>Unit Total Price</th>
                     <th>Line Total</th>
                     <th>Actions</th>                     
                  </tr>
               </thead>
               <tbody>
         

                
                <tr *ngFor="let s of dtl">
                    <td>{{s.line_number}}</td>
                    <td><a routerLink="/app/inventory/{{ s.product_id }}">{{ s.product_name}}</a></td>
                    <td>{{s.product_cond_name}}</td>
                    <td>{{s.unit_qty}}</td>
                    <td>$ {{s.unit_price}}</td>
                    <td>{{s.unit_tax_pct}} %</td>
                    <td>$ {{s.unit_total }}</td>
                    <td>$ {{s.line_total }}</td>
                    <td>
                        <button class="btn btn-warning " (click)="modal_open('edit_order_line');" > Edit</button>
                        <button class="btn btn-danger " (click)="order_line_delete(s.so_line_id,'ask');" > Delete</button>
                    </td>
                </tr>
                <tr><td colspan="9" class="tac">
                    <button type="button" class="btn btn-success" (click)="modal_open('add_order_line');">Add Item</button>
                </td></tr>



               </tbody>
            </table>


        
    </div>
</div>



<div class="row">
    <div class="col-md-12">

      <div id="add_shipment" class="modal">

        <!-- Modal content -->
        <div class="modal-content">

          <form id="new_shipment">
          <table class="table table-hover table-bordered" style="width:100%">
            <tr>
                <th class="bg-gray ">Tracking Number</th>
                <td>
                    <input class="form-control" name="shipping_tracking_number" type="text" >
                       
                </td>
            </tr>
            <tr>
                <th class="bg-gray ">Carrier</th>
                <td>
                    <select class="form-control" name="shipping_carrier_id" >
                        <option value="0">Select</option>
                        <option value="1">FedEx</option>
                        <option value="2">UPS</option>
                        <option value="3">USPS</option>
                        <option value="4">DHL</option>
                        <option value="5">OTHER</option>
                    </select>
                </td>
            </tr>

         </table>  
         <div class="tar">
            <button class="btn btn-danger " (click)="modal_close('add_shipment');"> Cancel</button>
            <button class="btn btn-success " (click)="shipment_add();"> Add New Shipment</button>
        </div>   
        <input type="hidden" name="so_id" value="{{ hdr.so_id }}" >

    </form>

          
        </div>

      </div>

            <h4 class="box-title text-info">Shipment Information : </h4>
            <table class="table table-hover table-bordered" style="width:100%" id="payments_table">
               <thead>
                  <tr class="bg-gray ">
                     <th>#</th>
                     <th>Date</th>
                     <th>Carrier</th>
                     <th>Shipping Method</th>
                     <th>Tracking Number</th>
                     <th>Shipment Status</th>
                     <th>Scheduled Date</th>
                     <th>Action</th>
                  </tr>
               </thead>
               <tbody>
                  <tr *ngFor="let shipment of shipments ">
                    <td>{{ shipment.line_number }}</td>
                    <td>N/A</td>
                    <td>{{ shipment.carrier_desc }}</td>
                    <td>N/A</td>
                    <td>{{ shipment.shipment_tracking_number }}</td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>N/A</td>
                  </tr>
                  <tr><td colspan="8" class="text-center text-bold"><button type="button" class="btn btn-success" (click)="modal_open('add_shipment');">Add Shipment</button></td></tr>                                             
                </tbody>
            </table>


        
    </div>
</div>






</div></div></div></section>