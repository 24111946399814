import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'app-inventorymovementdetail',
  templateUrl: './inventorymovementdetail.component.html',
  styleUrls: ['./inventorymovementdetail.component.scss']
})
export class InventorymovementdetailComponent implements OnInit {

  imovement;
  pid;
  pdetail;
  constructor(
    private ds: DataService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.pid = this.route.snapshot.params.id;





    this.ds.dsGet('inventory/movement/'+this.pid,this.route.snapshot.queryParamMap).subscribe((data)=>{
      this.imovement = JSON.parse(JSON.stringify(data)).items;
    });

    this.ds.dsGet('product/ii/'+this.pid,this.route.snapshot.queryParamMap).subscribe((data)=>{
      this.pdetail = JSON.parse(JSON.stringify(data)).hdr;
    });

  }

}
