import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss']
})
export class CustomerComponent implements OnInit {

  customerlist;
  
  constructor(
    private ds: DataService
  ) { }

  ngOnInit(): void {
    this.ds.dsGet('customer','{}').subscribe((data)=>{
      this.customerlist = JSON.parse(JSON.stringify(data)).items;
    });

  }

}
