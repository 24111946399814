<!-- Left side column. contains the sidebar -->
<aside class="main-sidebar">
  <!-- sidebar: style can be found in sidebar.less -->
  <section class="sidebar">

    <!-- search form -->
    <form action="#" class="sidebar-form" method="get">
      <div class="input-group">
        <input class="form-control" name="q" placeholder="Search..." type="text">
        <span class="input-group-btn">
                <button class="btn btn-flat" id="search-btn" name="search" type="submit"><i class="fa fa-search"></i>
                </button>
              </span>
      </div>
    </form>
    <!-- /.search form -->
    <!-- sidebar menu: : style can be found in sidebar.less -->




    <ul class="sidebar-menu" *ngxPermissionsExcept="['kioskccc']">
       <!--<li class="header">MAIN NAVIGATION</li>-->

       <li><a [routerLink]="['/app/dashboard']"><i class="fas fa-angle-right"></i> <span> Dashboard </span></a></li>

       <li *ngxPermissionsOnly="['syrup', 'customer']"><a [routerLink]="['/app/customer']" routerLinkActive="router-link-active"  ><i class="fas fa-angle-right"></i> Customers </a></li>
       <li *ngxPermissionsOnly="['syrup', 'so']"><a [routerLink]="['/app/so']" routerLinkActive="router-link-active"  ><i class="fas fa-angle-right"></i> Sales Orders </a></li>

       <li *ngxPermissionsOnly="['syrup', 'vendor']"><a [routerLink]="['/app/vendor']" routerLinkActive="router-link-active"  ><i class="fas fa-angle-right"></i> Vendors </a></li>
       <li *ngxPermissionsOnly="['syrup', 'po']"><a [routerLink]="['/app/po']" routerLinkActive="router-link-active"  ><i class="fas fa-angle-right"></i> Purchase Orders </a></li>
       <li *ngxPermissionsOnly="['syrup', 'po']"><a [routerLink]="['/app/wtb']" routerLinkActive="router-link-active"  ><i class="fas fa-angle-right"></i> Want to Buy </a></li>

       <li *ngxPermissionsOnly="['syrup', 'items']"><a [routerLink]="['/app/items']" routerLinkActive="router-link-active"  ><i class="fas fa-angle-right"></i> Items </a></li>

       <li *ngxPermissionsOnly="['syrup', 'inventory']"><a [routerLink]="['/app/inventory']" routerLinkActive="router-link-active"  ><i class="fas fa-angle-right"></i> Inventory </a></li>
       <li *ngxPermissionsOnly="['syrup']"><a [routerLink]="['/app/manage/inventory']" routerLinkActive="router-link-active"  ><i class="fas fa-angle-right"></i> Inventory Manage</a></li>
       <li *ngxPermissionsOnly="['syrup', 'test', 'inventory', 'shipping']"><a [routerLink]="['/app/test/inventory']" routerLinkActive="router-link-active"  ><i class="fas fa-angle-right"></i> Inventory Test</a></li>

       <!--   <li *ngxPermissionsOnly="['syrup', 'inventory']"><a [routerLink]="['/app/inventory/movement']" routerLinkActive="router-link-active"  ><i class="fas fa-angle-right"></i> Inventory Movement</a></li> -->




       <li *ngxPermissionsOnly="['syrup', 'shipping']"><a [routerLink]="['/app/shipping']" routerLinkActive="router-link-active"  ><i class="fas fa-angle-right"></i> Shipping </a></li>
       <li *ngxPermissionsOnly="['syrup', 'shipping', 'kiosk']"><a [routerLink]="['/app/shipping/recent']" routerLinkActive="router-link-active"  ><i class="fas fa-angle-right"></i> Recent Shipment </a></li>
       <li *ngxPermissionsOnly="['syrup', 'shipping']"><a [routerLink]="['/app/shipping/in']" routerLinkActive="router-link-active"  ><i class="fas fa-angle-right"></i> In</a></li>
       <li *ngxPermissionsOnly="['syrup', 'shipping']"><a [routerLink]="['/app/shipping/manualin']" routerLinkActive="router-link-active"  ><i class="fas fa-angle-right"></i> Manual In</a></li>
       <li *ngxPermissionsOnly="['syrup', 'shipping']"><a [routerLink]="['/app/shipping/out']" routerLinkActive="router-link-active"  ><i class="fas fa-angle-right"></i> Out </a></li>

       <li *ngxPermissionsOnly="['syrup', 'kiosk']"><a [routerLink]="['/app/shipping/kiosk']" routerLinkActive="router-link-active"  ><i class="fas fa-angle-right"></i> Kiosk </a></li>



    </ul>
  </section>
  <!-- /.sidebar -->
</aside>
