import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../../services/data.service';

declare var $;

@Component({
  selector: 'app-inventorytest',
  templateUrl: './inventorytest.component.html',
  styleUrls: ['./inventorytest.component.scss']
})
export class InventorytestComponent implements OnInit {

  pdata:any;
  dItems:any;
  inputVal: string;
  iiid:number;

  constructor(
    private router: Router,
    private ds: DataService
  ) { }

  ngOnInit(): void {
    const component = this;
    $('#input_test').focus();

    $('#input_test').keypress(function (e) {
      this.inputVal = $('#input_test').val();
      const key = e.which;
      if (key === 13) { component.gserial(); }
    });

  }




  gserial(){
    this.ds.dsGet('serial?q='+$('#input_test').val(),'{}').subscribe((data)=>{
      this.dItems = JSON.parse(JSON.stringify(data)).items;
    });
  }



  updateCondition(iiid,cid){
    const fdata = {
        action: 'update_condition',
        inventory_item_id: iiid,
        new_condition_id:cid
    };

    this.ds.dsPost('inventory/movement/'+iiid,fdata).subscribe((data)=>{
      this.gserial();
    });
  }



  prePrintLabel(sn,cd,pn){
    // prepare the labels
    console.log(sn + cd + pn);
    $('#label_1_html').html('<img src="https://barcode.tec-it.com/barcode.ashx?data='+sn+'&code=&multiplebarcodes=false&translate-esc=false&unit=Min&dpi=96&imagetype=Gif&rotation=0&color=%23000000&bgcolor=%23ffffff&codepage=&qunit=Mm&quiet=0" />');

    $('#label_2_html').html('<img src="https://barcode.tec-it.com/barcode.ashx?data='+sn+'&code=&multiplebarcodes=false&translate-esc=false&unit=Min&dpi=96&imagetype=Gif&rotation=0&color=%23000000&bgcolor=%23ffffff&codepage=&qunit=Mm&quiet=0" />' + '<br />' + pn);


    // open modal
    this.modal_open('modal-label');
  }

  printLabel(labelType){
    console.log(labelType);

    // print label
/*  
    alert($('#label_2_html').html());
    var mywindow = window.open("", '_blank');
    mywindow.document.write('<p>' + $('#label_'+labelType+'_html').html() + '</p>');
    mywindow.print();
*/
    var innerContents = $('#label_'+labelType+'_html').html();
    var popupWindow = window.open('', 'Print');

    popupWindow.document.write('<!DOCTYPE html><html><head><style type="text/css">@media print { body { -webkit-print-color-adjust: exact; } }</style></head><body> ' + innerContents + ' </body></html>');
    popupWindow.focus();
 //   popupWindow.stop();

    popupWindow.print();
   // popupWindow.close();



    // close modal


    this.modal_close('modal-label');




  }

  modal_open(modalId){
    $('#'+modalId).removeClass('fade').addClass('show');
//    this.gitems();

  }

  modal_close(modalId){
    $('#'+modalId).removeClass('show').addClass('fade');
  }


  changeCondition(iiid,cid){
    this.iiid = iiid;
    $('.cbtn').removeClass('btn-info').addClass('btn-default');
    $('.cbtn'+cid).removeClass('btn-info btn-default').addClass('btn-info');
    this.modal_open('modal-condition');
  }

  changeConditionP(ncid){
    console.log(this.iiid);
    console.log(ncid);

    this.modal_close('modal-condition');

    this.updateCondition(this.iiid,ncid);

    // this.gserial();

  }


}
