import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-po',
  templateUrl: './po.component.html',
  styleUrls: ['./po.component.scss']
})
export class PoComponent implements OnInit {

  constructor(    private ds: DataService
    ) { }
pos:any;
  ngOnInit(): void {

    this.ds.dsGet('po',{}).subscribe((data)=>{ 
      this.pos = JSON.parse(JSON.stringify(data)).items;
    });

  }

}
