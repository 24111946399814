import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shipout',
  templateUrl: './shipout.component.html',
  styleUrls: ['./shipout.component.scss']
})
export class ShipoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }


  shipItem(){
    
  }
}
