import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { Router, ActivatedRoute } from '@angular/router';

declare var $;


@Component({
  selector: 'app-vendoradd',
  templateUrl: './vendoradd.component.html',
  styleUrls: ['./vendoradd.component.scss']
})
export class VendoraddComponent implements OnInit {

  constructor(
    private ds: DataService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  vdata:any;

  ngOnInit(): void {
    const pid = this.route.snapshot.params.id;

    if(pid){
      this.ds.dsGet('vendor/'+pid,'{}').subscribe((data)=>{
        this.vdata = JSON.parse(JSON.stringify(data)).items;
        $('#btn_ok').html('Update');
        $('#tt').html('Update Customer');
      });
    }else{
      console.log('m>add');
    }
  }

  create(fn){

    const fdata = {};
    $('#'+fn).serializeArray().map(function(x){fdata[x.name] = x.value;});
    this.ds.dsPost('vendor/add',fdata).subscribe((data)=>{
      this.router.navigateByUrl('/app/vendor/'+data.lid);
    });

  }

}


