import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NgxPermissionsGuard, NgxPermissionsRouterData } from 'ngx-permissions';

// import { DashboardComponent } from './ims/dashboard/dashboard.component';

// import { LoginComponent } from './auth/containers/login/login.component';
import { AuthGuard } from './modules/auth/guards/auth.guard';
import { RandomGuard } from './modules/auth/guards/random.guard';
import { ImsGuard } from './modules/auth/guards/ims.guard';



import { SellernodeModule } from './modules/sellernode/sellernode.module';
import { AuthModule } from './modules/auth/auth.module';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/app' },
  {
    path: 'login',
    loadChildren: () => AuthModule,
    canActivate: [AuthGuard]
  },
  {
    path: 'app',
    loadChildren: () => SellernodeModule,
    canActivate: [RandomGuard],
    canLoad: [RandomGuard]
  },
  {path: '**', redirectTo: ''}
];


@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule { }
