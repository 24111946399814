import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-customerdetail',
  templateUrl: './customerdetail.component.html',
  styleUrls: ['./customerdetail.component.scss']
})
export class CustomerdetailComponent implements OnInit {


  constructor(
    private ds: DataService,
    private router: Router,
    private route: ActivatedRoute
  ) { }



  cdata:any;
  sos:any;

  ngOnInit(): void {
    const pid = this.route.snapshot.params.id;
    // Get customer Detail
    this.ds.dsGet('customer/'+pid,'{}').subscribe((data)=>{
      this.cdata = JSON.parse(JSON.stringify(data)).items;
    });


    // Get customer SOs
    this.ds.dsGet('customer/'+pid+'/so','{}').subscribe((data)=>{
      this.sos = JSON.parse(JSON.stringify(data)).items;
    });


  }

}
