<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1 id="tt">Vendor Detail</h1>
            </div>
            <div class="col-sm-6">

                <a [routerLink]="['/app/vendor']" routerLinkActive="router-link-active"   class="btn btn-success btn-flat" ><i class="fas fa-angle-right"></i> All Vendors </a>
               
            </div>
        </div>
    </div>
</section>


<section class="content"><div class="row"><div class="col-md-12"><div class="box box-info ">




    

    <div class="row">
        <div class="col-md-12">
    
            <table class="table table-hover table-bordered" style="width:100%">
                <tr>
                  <th class="bg-gray ">Vendor Number</th>
                  <td><input class="form-control" type="text" value="{{vdata[0].vendor_id}}" disabled></td>
                </tr>

                <tr>
                  <th class="bg-gray ">Created By</th>
                  <td><input class="form-control" type="text" value="Engin" disabled></td>
                </tr>                      

                <tr>
                  <th class="bg-gray ">Vendor Name</th>
                  <td><input class="form-control" type="text" name="vendor_name" value="{{vdata[0].vendor_name}}" disabled></td>
                </tr>

                <tr>
                  <th class="bg-gray ">Note</th>
                  <td><textarea name="note" class="form-control" disabled>{{vdata[0].note}}</textarea></td>
                </tr>  

                <tr>
                    <th class="bg-gray ">Address</th>
                    <td><textarea name="address" class="form-control" disabled>{{vdata[0].address}}</textarea></td>
                </tr> 
                
           </table> 

    
            
        </div>
    </div>
    


    <div class="row">

        <div class="col-md-12">
            <div class="box box-info ">
            <table id="example1" class="table table-bordered table-striped">
                <thead>
                    <tr>
                        <th>PO #</th>
                        <th>Date</th>
                        <th>Status</th>
                        <th>Reference</th>
                        <th>Created By</th>
                        <th>Note</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>

                    <tr *ngFor="let p of pos">
                        <td><a routerLink="/app/po/{{ p.po_id }}">{{ p.po_id}}</a></td>

                        <td>{{ p.po_date}}</td>
                        <td>Draft</td>
                        <td>{{ p.reference}}</td>

                        <td>0</td>
                        <td>3</td>
                        <td>
                            <div class="btn-group" title="View Account">
                                <a class="btn btn-primary btn-o dropdown-toggle" data-toggle="dropdown" href="#" aria-expanded="false">
                                    Action <span class="caret"></span>
                                </a>
                                <ul role="menu" class="dropdown-menu dropdown-light pull-right">
                                    <li>
                                        <a title="View Invoice" [routerLink]="['/app/po/123']" [queryParams]="{action: 'view'}"><i class="fa fa-fw fa-eye text-blue"></i>View
                                            Purchase</a>
                                    </li>
                                    <li>
                                        <a title="Update Record ?" [routerLink]="['/app/po/123']" [queryParams]="{action: 'edit'}">
                                            <i class="fa fa-fw fa-edit text-blue"></i>Edit
                                        </a>
                                    </li>
                                    <li>
                                        <a title="Update Record ?" [routerLink]="['/app/po/123']" [queryParams]="{action: 'print'}">
                                            <i class="fa fa-fw fa-print text-blue"></i>Print
                                        </a>
                                    </li>
                                    <li>
                                        <a title="Update Record ?" [routerLink]="['/app/po/123']" [queryParams]="{action: 'return'}">
                                            <i class="fa fa-fw fa-print text-blue"></i>Create Return
                                        </a>
                                    </li>                                    
                                </ul>
                            </div>
                        </td>
                    </tr>
                    


                </tbody>
            </table>
            </div>
        </div>
    </div>



</div></div></div></section>