<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Inventory Test</h1>
            </div>
            <div class="col-sm-6">

            </div>
        </div>
    </div>
</section>


<section class="content">

    <div class="row">

        <div class="col-md-12">
            <div class="box box-info ">
                <form class="form" id="f_kiosk" accept-charset="utf-8">
                    <input type="text"  class="form-control input-lg" id="input_test" name="input_test" placeholder="Search Serial" />
                </form>                  
            </div>
        </div>
    </div>
    

    <div class="row" *ngFor="let item of dItems">


        <div class="col-md-3">
            <div class="box ">
                <div class="box-header with-border">
                    <h3 class="box-title"> {{ item.serial_number }}</h3>
                </div>
                <div class="box-body">
                    {{ item.product_name }}
                </div>
            </div>                    
        </div>

        <div class="col-md-2">
            <div class="box ">
                <div class="box-header with-border">
                    <h3 class="box-title">Condition</h3>
                </div>
                <div class="box-body">
                    <button type="button" class="btn btn-default btn-lg btn-block" (click)="changeCondition(item.inventory_item_id,item.condition_id );" id = "{{ item.inventory_item_id }}"  value = "{{ item.condition_id }}">
                        {{ item.condition_desc }}
                    </button>
                </div>
            </div>                    
        </div>

        <div class="col-md-2">
            <div class="box ">
                <div class="box-header with-border">
                    <h3 class="box-title">Location</h3>
                </div>
                <div class="box-body">
                    <button type="button" class="btn btn-default btn-lg btn-block" disabled data-toggle="modal" data-target="#modal-location">
                        Dallas-105
                    </button>
                </div>
            </div>                    
        </div>

        <div class="col-md-2">
            <div class="box ">
                <div class="box-header with-border">
                    <h3 class="box-title">Label</h3>
                </div>
                <div class="box-body">
                    <button type="button" class="btn btn-default btn-lg btn-block" (click)="prePrintLabel(item.serial_number,item.condition_desc,item.product_name)"  >
                        Print
                    </button>
                </div>
            </div>                    
        </div>
        <div class="col-md-3 hidden">

            <div class="box ">
                <div class="box-header with-border">
                    <h3 class="box-title">NOTES</h3>
                </div>
                <div class="box-body">
                    <blockquote>
                        <p>Item Note 1 </p>
                        <small>Engin, 2020/09/23</small>
                    </blockquote>

                    <blockquote>
                        <p>Item Note 2 </p>
                        <small>Serhat, 2020/09/24</small>
                    </blockquote>

                </div>

                <div class="box-footer">
                    <form action="#" method="post">
                      <div class="input-group">
                        <input type="text" name="message" placeholder="Type Message ..." class="form-control">
                            <span class="input-group-btn">
                              <button type="submit" class="btn btn-primary btn-flat">Add Note</button>
                            </span>
                      </div>
                    </form>
                </div>

            </div>                        

        </div>
        
    </div>


    <div class="modal fade" id="modal-condition">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body">
                <button type="button" class="btn btn-default btn-lg btn-block cbtn cbtn1" (click)="changeConditionP(1)"> NIB - New in Box</button>
                <button type="button" class="btn btn-default btn-lg btn-block cbtn cbtn2" (click)="changeConditionP(2)"> NOB - New Open Box</button>
                <button type="button" class="btn btn-default btn-lg btn-block cbtn cbtn3" (click)="changeConditionP(3)"> REF - Refurbished</button>
                <button type="button" class="btn btn-default btn-lg btn-block cbtn cbtn4" (click)="changeConditionP(4)"> USED</button>
                <button type="button" class="btn btn-default btn-lg btn-block cbtn cbtn5" (click)="changeConditionP(5)"> TEST</button>
                <button type="button" class="btn btn-default btn-lg btn-block cbtn cbtn6" (click)="changeConditionP(6)"> PART</button>
                <button type="button" class="btn btn-default btn-lg btn-block cbtn cbtn7" (click)="changeConditionP(7)"> RETURN</button>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-warning" (click)="modal_close('modal-condition')" >Close</button>
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
      <!-- /.modal -->    

      <div class="modal fade" id="modal-label">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body">
                <button type="button" class="btn btn-default btn-lg btn-block" (click)="printLabel(1)">Type 1</button>
                <button type="button" class="btn btn-default btn-lg btn-block" (click)="printLabel(2)">Type 2</button>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-warning" (click)="modal_close('modal-label')" >Close</button>
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
      <!-- /.modal -->         
      
      

      <div class="fade" id='label_1_html'>
            l 1
      </div>

      <div class="fade" id='label_2_html'>
        l 2

    </div>      

</section>