import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-vendordetail',
  templateUrl: './vendordetail.component.html',
  styleUrls: ['./vendordetail.component.scss']
})
export class VendordetailComponent implements OnInit {

  constructor(
    private ds: DataService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  vdata:any;
  pos:any;

  ngOnInit(): void {
    const pid = this.route.snapshot.params.id;

      this.ds.dsGet('vendor/'+pid,'{}').subscribe((data)=>{
        this.vdata = JSON.parse(JSON.stringify(data)).items;
      });

      this.ds.dsGet('vendor/'+pid+'/po',{}).subscribe((data)=>{ 
        this.pos = JSON.parse(JSON.stringify(data)).items;
      });

  }

}
