import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { Router, ActivatedRoute } from '@angular/router';

declare var $;

@Component({
  selector: 'app-customeradd',
  templateUrl: './customeradd.component.html',
  styleUrls: ['./customeradd.component.scss']
})
export class CustomeraddComponent implements OnInit {

  constructor(
    private ds: DataService,
    private router: Router,
    private route: ActivatedRoute
  ) { }



  cdata:any;

  ngOnInit(): void {
    const pid = this.route.snapshot.params.id;
    console.log($('#customer_id').val());
    if(pid){
      this.ds.dsGet('customer/'+pid,'{}').subscribe((data)=>{
        this.cdata = JSON.parse(JSON.stringify(data)).items;
        $('#btn_ok').html('Update');
        $('#tt').html('Update Customer');
      });
    }else{
      console.log('m>add');
    }
  }

  create(fn){

    const fdata = {};
    $('#'+fn).serializeArray().map(function(x){fdata[x.name] = x.value;});
    this.ds.dsPost('customer/add',fdata).subscribe((data)=>{
      this.router.navigateByUrl('/app/customer/'+data.lid);
    });

  }

}
