<section class="content">
  
    <div class="row p5">
        <div class="col-md-12 kiosk_current ba1" >
            <div class="row bb1 ">
                <div class="col-md-4" id="kuser">User</div>
                <div class="col-md-4 tac bl1 clock">15:47:26</div>
                <div class="col-md-4 tac bl1 bgr" id="kdir">NA</div>
            </div>            
            <div class="row bb1">
                <div class="col-md-12 tac" id="ktn">Tracking #</div>
            </div>
            <div class="row bb1">
                <div class="col-md-12 tac" id="kn">Product Name</div>
            </div>            
            <div class="row bb1">
                <div class="col-md-12 tac" id="kpn">Part Number</div>
            </div>
            <div class="row ">
                <div class="col-md-8 tac" id="ksn">Serial Number</div>
                <div class="col-md-4 tac bl1" id="kcond">Condition</div>
            </div>
            <div class="row bt1" id="knote_row">
                <div class="col-md-12 tac" id="knote">Receiving Note</div>
            </div>            
        </div>
    </div>

    

  <p></p>
    <div class="row p5">
        <div class="col-md-3 kiosk_pass"  *ngFor="let item of recentItems">
            <div class="row bb1 bt1">
                <div class="col-md-12 tac">{{ item.created_at+'z' | date:'y/MM/d, h:mm a' }}</div>
            </div>
            <div class="row bb1">
                <div class="col-md-8">{{ item.firstname}}</div>
                <div class="col-md-4 tac bl1">{{ item.type_desc}}</div>
            </div> 
            <div class="row bb1">
                <div class="col-md-12 tac">{{ item.shipment_tracking_number}}</div>
            </div>
            <div class="row bb1">
                <div class="col-md-12 tac">{{ item.brand_name}} {{ item.part_number}} / {{ item.condition_name}}</div>
            </div>
            <div class="row bb1">
                <div class="col-md-12 tac">{{ item.serial_number}}</div>
            </div>
        </div>

        
    </div>


    
    <div class="row">
        <div class="col-md-12">
            <form class="form" id="f_kiosk" accept-charset="utf-8">
                <input type="text"  class="form-control" id="input_kiosk" name="input_kiosk" />
                <input type="hidden" id="fi_user" name="fi_user" value="iuser">
                <input type="hidden" id="fi_direction"  name="fi_direction" value="shipout">
                <input type="hidden" id="fi_tn" name="fi_tn" value="itn">
                <input type="hidden" id="fi_pn"  name="fi_pn" value="ipn">
                <input type="hidden" id="fi_sn" name="fi_sn" value="isn">
                <input type="hidden" id="fi_note" name="fi_note" value="inote">
                <input type="hidden" id="fi_condition" name="fi_condition" value="icond">
            
            </form>    
        </div>
        <div class="col-md-2">
            <ul class="cct">
                <li>note000</li>
                <li>note001</li>
                <li>note002</li>
                <li>note003</li>
                <li>note004</li>
                <li>note005</li>
                <li>note010</li>
                <li>note011</li>

            </ul>

        </div>  
        
        <div class="col-md-2">
            <ul class="cct">
                <li>formclear</li>
                <li>formok</li>
                <li>.</li>
                <li>shipin</li>
                <li>shipout</li>

               
            </ul>

        </div> 

        <div class="col-md-2">
            <ul class="cct">
                <li>PN-01</li>
                <li>PN-02</li>
                <li>PN-03</li>
                <li>PN-04</li>
                <li>PN-XX</li>
                <li>885609012360</li>
                <li>885609011141</li>

                <li>CONDITION-NIB</li>
                <li>CONDITION-NOB</li>
                <li>CONDITION-REF</li>
                <li>CONDITION-USED</li>
                <li>CONDITION-PART</li>
                <li>CONDITION-TEST</li>
                <li>CONDITION-RETURN</li>
                

            </ul>

        </div> 

        <div class="col-md-2">
            <ul class="cct">

                <li>Serial</li>
                <li>JU6-US-KCR3214B</li>
                <li>UN4-US-KNA1527A</li>
                <li>JA1-US-KEA1960B</li>
                <li>NKS223500FF</li>
               
            </ul>

        </div>  

        <div class="col-md-2">
            <ul class="cct">

                <li>mehmet@rnw.us</li>
                <li>enes@rnw.us</li>
                <li>serhat@rnw.us</li>
                <li>engin@rnw.us</li>
               
            </ul>

        </div>        
        
        <div class="col-md-2">
            <ul class="cct">
                <li>9400109206094885822926</li>
                <li>9622001560008524261900788939294332</li>
                <li>1Z58333F0314610279</li>
                <li>1ZR0967V0317115795</li>
                <li>392865723991</li>
                <li>391764303158</li>

            </ul>

        </div>         

    </div>
</section>
