<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Add Item</h1>
            </div>
            <div class="col-sm-6">

            </div>
        </div>
    </div>
</section>
<section class="content">
    <div class="row">

        <div class="col-md-12">

            <div class="box box-info ">
                     
                <form  class="form" id="items-form" accept-charset="utf-8">
                    <div class="box-body">
                     <div class="row">
                        <div class="form-group col-md-4">
                           <label for="item_name">Item Name</label>
                           <input type="text" class="form-control" name="item_name"  value="{{pdata.product_name}}" >
                           <span id="item_name_msg" style="display:none" class="text-danger"></span>
                        </div>

                        <div class="form-group col-md-4">
                            <label for="brand">Brand</label>
                            <input type="text" class="form-control" name="brand"  value="{{pdata.brand_name}}">
                            <span id="brand_msg" style="display:none" class="text-danger"></span>
                         </div>

                        <div class="form-group col-md-4">
                            <label for="category">Category</label>
                            <input type="text" class="form-control" name="category" value="{{pdata.category_name}}">
                            <span id="category_msg" style="display:none" class="text-danger"></span>
                         </div>

                        
                        <div class="form-group col-md-4">
                           <label for="sku">SKU</label>
                           <input type="text" class="form-control"  name="sku" >
                           <span id="sku_msg" style="display:none" class="text-danger"></span>
                        </div>

                        
                        <div class="form-group col-md-4">
                           <label for="part_number">Part Number</label>
                           <input type="text" class="form-control no_special_char"  name="part_number" value="{{pdata.part_number}}" >
                           <span id="part_number_msg" style="display:none" class="text-danger"></span>
                        </div>
                        <div class="form-group col-md-4">
                            <label for="tags">UPCs </label>
                            <input type="text" class="form-control no_special_char" id="tags" name="tags" value="{{pdata.tag}}">
                            <span id="tags_msg" style="display:none" class="text-danger"></span>
                         </div>


                     </div>
                     <input class="form-control" type="hidden" name="product_id"   value="{{pdata.product_id}}" >

                     <!-- /.box-body -->
                     <div class="box-footer">
                        <div class="col-sm-8 col-sm-offset-2 text-center">
                           <!-- <div class="col-sm-4"></div> -->
                                                            <div class="col-md-3 col-md-offset-3">
                              <button type="button" id="save" class=" btn btn-block btn-success" title="Save Data" (click)="saveProduct();">Add Item</button>
                           </div>
                           <div class="col-sm-3">
                              <button type="button" class="col-sm-3 btn btn-block btn-warning close_btn" title="Go Dashboard"  [routerLink]="['/app/items']" routerLinkActive="router-link-active">Close</button>
                           </div>
                        </div>
                     </div>
                     <!-- /.box-footer -->
                                    </div></form>
               <!-- /.box -->
            </div>


        </div>
    </div>
</section>