import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { ActivatedRoute } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';

declare var $;

@Component({
  selector: 'app-podetail',
  templateUrl: './podetail.component.html',
  styleUrls: ['./podetail.component.scss']
})
export class PodetailComponent implements OnInit {

  constructor(
    private ds: DataService,
    private route: ActivatedRoute,
    private permissionsService: NgxPermissionsService

  ) { }

  shipments:any;
  dtl:any;
  hdr:any;
  dItems:any;
  dItemsf=0;


  ngOnInit(): void {
    const poId = this.route.snapshot.params.id;
    this.ds.dsGet('po/'+poId, '{}').subscribe((data)=>{ 

     // this.dtl = data.dtl;
     // this.hdr = data.hdr;

     this.dtl = JSON.parse(JSON.stringify(data)).dtl;
     this.hdr = JSON.parse(JSON.stringify(data)).hdr;


    });

    this.gshipment();

  }

  gshipment(){
    const poId = this.route.snapshot.params.id;
    this.ds.dsGet('po/'+poId+'/shipment', '{}').subscribe((data)=>{
      this.shipments = JSON.parse(JSON.stringify(data)).items;
    });
  }

  gitems(){
    if(this.dItemsf === 0 ){
      this.ds.dsGet('products',{}).subscribe((data)=>{
        // this.dItems = data.items;
        this.dItems = JSON.parse(JSON.stringify(data)).items;
        this.dItemsf = 1;
        $('.select2').select2();

      });
    }
  }


  modal_open(modalId){
    $('#'+modalId).addClass('show');
    this.gitems();

  }

  modal_close(modalId){
    $('#'+modalId).removeClass('show');
  }




  shipment_add(){
    var fdata = {};
    $('#new_shipment').serializeArray().map(function(x){fdata[x.name] = x.value;}); 

    if($('select[name=shipping_carrier_id]').val()==='0'){alert('select shipping carrier'); return false;}
    if($('input[name=shipping_tracking_number]').val()===''){alert('enter tracking number'); return false;}

    this.ds.dsPost('po/shipment',fdata).subscribe((data)=>{ 
      this.gshipment();
      this.modal_close('add_shipment');
    });
  }



  po_line_add(){
    var fdata = {};
    $('#newline').serializeArray().map(function(x){fdata[x.name] = x.value;}); 

    if($('select[name=item_id]').val()==='0'){alert('select item'); return false;}
    if($('select[name=item_condition]').val()==='0'){alert('select condition'); return false;}

    this.ds.dsPost('poline/add',fdata).subscribe((data)=>{ 
      this.ngOnInit();
      this.modal_close('add_po_line');
    });
  }

  po_line_delete(poLineId,act){
    if (act ==='ask'){
      $('#line_to_delete').val(poLineId);
      this.modal_open('delete_po_line');
    }

    if (act ==='cancel'){
      $('#line_to_delete').val(poLineId);
      this.modal_close('delete_po_line');
    }

    if (act ==='ok'){
      this.ds.dsGet('poline/delete/'+$('#line_to_delete').val(),'{}').subscribe((data)=>{ 
        this.ngOnInit();
        $('#line_to_delete').val('0');

        this.modal_close('delete_po_line');
      });
    }
  }


}
