<header class="main-header">
  <!-- Logo -->
  <a class="logo" [routerLink]="['/app']" routerLinkActive="router-link-active">
    <!-- mini logo for sidebar mini 50x50 pixels -->
    <span class="logo-mini"><b>S</b>N</span>
    <!-- logo for regular state and mobile devices -->
    <span class="logo-lg"><b>Seller</b> Node</span>
  </a>
  <!-- Header Navbar: style can be found in header.less -->
  <nav class="navbar navbar-static-top">
    <!-- Sidebar toggle button-->
    <a class="sidebar-toggle" data-toggle="push-menu" href="#" role="button">
      <span class="sr-only">Toggle navigation</span>
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
    </a>

    <div class="btn-group hidden-xs ">
      <a href="#" class="btn navbar-btn btn-success dropdown-toggle " data-toggle="dropdown" aria-expanded="true">
          <i class="fa fa-plus"></i> 
      </a>
      <ul class="dropdown-menu">
                              <li class="border_bottom">
              <a [routerLink]="['/app/so/add']" routerLinkActive="router-link-active"><h4><i class="fa fa-plus text-green"></i> Sales</h4></a>
            </li> 
                                                <li class="border_bottom">
              <a [routerLink]="['/app/po/add']" routerLinkActive="router-link-active"><h4><i class="fa fa-plus text-green"></i> Purchase</h4></a>
            </li> 
                                                <li class="border_bottom">
              <a [routerLink]="['/app/customer/add']" routerLinkActive="router-link-active"><h4><i class="fa fa-plus text-green"></i> Customer</h4></a>
            </li> 
                                                <li class="border_bottom">
              <a [routerLink]="['/app/vendor/add']" routerLinkActive="router-link-active"><h4><i class="fa fa-plus text-green"></i> Vendor</h4></a>
            </li>
                                                <li class="border_bottom">
              <a [routerLink]="['/app/items/add']" routerLinkActive="router-link-active"><h4><i class="fa fa-plus text-green"></i> Item</h4></a>
            </li> 
 
                        </ul>
  </div>

    <div class="navbar-custom-menu">
      <ul class="nav navbar-nav">
        <li class="dropdown user user-menu">
          <a class="dropdown-toggle" data-toggle="dropdown" href="#">
            <img alt="User Image" class="user-image" src="assets/dist/img/avatar5.png">
            <span class="hidden-xs">Suleyman</span>
          </a>
          <ul class="dropdown-menu">
            <!-- Menu Footer-->
            <li class="user-footer">
              <div class="pull-right">
                <a class="btn btn-default btn-flat" (click)="logout();">Sign out</a>
              </div>
            </li>
          </ul>
        </li>
        <!-- Control Sidebar Toggle Button 
        <li>
          <a data-toggle="control-sidebar" href="#"><i class="fa fa-gears"></i></a>
        </li>-->
      </ul>
    </div>
  </nav>
</header>
