<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Shipping Out</h1>
            </div>
            <div class="col-sm-6">

            </div>
        </div>
    </div>
</section>
<section class="content">
    <div class="row">

        <div class="col-md-12">

            <div class="box box-info ">
                     
                <form  class="form" id="items-form" accept-charset="utf-8">
                    <div class="box-body">
                     <div class="row">
                        <div class="form-group col-md-4">
                           <label for="item_name">Tracking Number</label>
                           <input type="text" class="form-control" name="item_name">
                           <span id="item_name_msg" style="display:none" class="text-danger"></span>
                        </div>

                        <div class="form-group col-md-4">
                            <label for="brand">Serial Number</label>
                            <input type="text" class="form-control" name="brand">
                            <span id="brand_msg" style="display:none" class="text-danger"></span>
                         </div>

                        


                     </div>

                     <!-- /.box-body -->
                     <div class="box-footer">
                        <div class="col-sm-8 col-sm-offset-2 text-center">
                           <!-- <div class="col-sm-4"></div> -->
                                                            <div class="col-md-3 col-md-offset-3">
                              <button type="button" id="save" class=" btn btn-block btn-success" title="shipItem" (click)="shipItem();">Ship</button>
                           </div>
                           <div class="col-sm-3">
                              <button type="button" class="col-sm-3 btn btn-block btn-warning close_btn" title="Go Dashboard"  [routerLink]="['/app/items']" routerLinkActive="router-link-active">Close</button>
                           </div>
                        </div>
                     </div>
                     <!-- /.box-footer -->
                                    </div></form>
               <!-- /.box -->
            </div>


        </div>
    </div>
</section>