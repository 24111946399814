import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-shipping',
  templateUrl: './shipping.component.html',
  styleUrls: ['./shipping.component.scss']
})
export class ShippingComponent implements OnInit {

  constructor(
    private ds: DataService
  ) { }

  shipmentList : any;

  ngOnInit(): void {
    this.ds.dsGet('shipment','{}').subscribe((data)=>{
      this.shipmentList = JSON.parse(JSON.stringify(data)).items;
    });

  }

}
