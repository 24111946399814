<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>{{ pdetail.product_name }} - [ <b>{{ pdetail.serial_number }}</b> ]</h1>
                <p>PID:{{ pdetail.product_id }}, Part#:{{ pdetail.part_number }}, UPCs: {{ pdetail.tag }}, BRAND: {{ pdetail.brand_name }} </p>
            </div>
            <div class="col-sm-6">
                <a routerLink="/app/manage/inventory/{{ pdetail.inventory_item_id }}" routerLinkActive="router-link-active"><button type="button" class="btn btn-success btn-flat">Manage Inventory</button></a>
            </div>
        </div>
    </div>
</section>



<section class="content">

    <div class="row">

        <div class="col-md-12">
            <div class="box box-info ">
            <table id="example1" class="table table-bordered table-striped">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Date</th>
                        <th>Reference</th>
                        <th>Created By</th>
                        <th>Location</th>
                        <th>Condition</th>
                        <th>Note</th>
                     </tr>
                </thead>
                <tbody>

                    

                    <tr *ngFor="let ii of imovement">
                        <td>{{ ii.inventory_item_log_id}}</td>
                        <td>{{ ii.created_at+'z' | date:'y/MM/d, h:mm a' }}</td>
                        <td>{{ ii.type_name }} {{ ii.shipment_tracking_number }} </td>
                        <td>{{ ii.firstname}}</td>
                        <td>---</td>
                        <td>---</td>
                        <td>{{ ii.note}}</td>
                    </tr>                    


                    
                    

                </tbody>
            </table>
            </div>
        </div>
    </div>

</section>