import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { of, Observable, BehaviorSubject, throwError } from 'rxjs';
import { catchError, mapTo, tap } from 'rxjs/operators';
import { config } from './../../../config';
import { Tokens } from '../models/tokens';
import { NgxPermissionsService } from 'ngx-permissions';
import * as jwt_decode from 'jwt-decode';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private readonly ACCESS_TOKEN = 'ACCESS_TOKEN';
  private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';
  private loggedUser: string;

  private loginStatus =  new BehaviorSubject<boolean>(this.isLoggedIn());

  constructor(
    private http: HttpClient,
    private permissionsService: NgxPermissionsService
    ) {}

  login(user: { username: string, password: string }): Observable<boolean> {
    return this.http.post<any>(`${config.apiUrl}/kapici/login`, user)
      .pipe(
        tap(
          tokens => {
            this.doLoginUser(user.username, tokens);
            const tokenInfo = jwt_decode(tokens.accessToken);
            this.permissionsService.loadPermissions(tokenInfo.permission);
          }
        ),
        mapTo(true),
        catchError(error => {
          alert(error.error);
          return of(false);
        }));
  }

  get isLoggesIn() 
  {
      return this.loginStatus.asObservable();
  }


  logout() {
    return this.http.post<any>(`${config.apiUrl}/kapici/logout`, {
      'refreshToken': this.getRefreshToken()
    }).pipe(
      tap(() => this.doLogoutUser()),
      mapTo(true),
      catchError(error => {
        alert(error.error);
        return of(false);
      }));
  }

  isLoggedIn() {
    const t = this.getJwtToken();
    if(!!t){
      const tokenInfo = jwt_decode(t);
      this.permissionsService.loadPermissions(tokenInfo.permission);
    }
    return !!t;
  }

  refreshToken() {
    return this.http.post<any>(`${config.apiUrl}/kapici/refresh`, { refreshToken: this.getRefreshToken()})
    .pipe(
      tap((tokens: Tokens) => {
        this.storeJwtToken(tokens.accessToken);
      })
    );
  }

  getJwtToken() {
    return localStorage.getItem(this.ACCESS_TOKEN);
  }

  private doLoginUser(username: string, tokens: Tokens) {
    this.loggedUser = username;
    this.storeTokens(tokens);
  }

  private doLogoutUser() {
    this.loggedUser = null;
    this.removeTokens();
  }

  private getRefreshToken() {
    return localStorage.getItem(this.REFRESH_TOKEN);
  }

  private storeJwtToken(jwt: string) {
    localStorage.setItem(this.ACCESS_TOKEN, jwt);
  }

  private storeTokens(tokens: Tokens) {
    localStorage.setItem(this.ACCESS_TOKEN, tokens.accessToken);
    localStorage.setItem(this.REFRESH_TOKEN, tokens.refreshToken);
  }

  private removeTokens() {
    localStorage.removeItem(this.ACCESS_TOKEN);
    localStorage.removeItem(this.REFRESH_TOKEN);
  }
}
