import { Component, OnInit } from '@angular/core';

declare var $;

@Component({
  selector: 'app-sellernode',
  templateUrl: './sellernode.component.html',
  styleUrls: ['./sellernode.component.scss']
})
export class SellernodeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.dispatchEvent(new Event('resize'));
    $('body').addClass('hold-transition skin-blue sidebar-mini');
  }
}
