import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
declare var $;

@Component({
  selector: 'app-shipinmanual',
  templateUrl: './shipinmanual.component.html',
  styleUrls: ['./shipinmanual.component.scss']
})
export class ShipinmanualComponent implements OnInit {

  constructor(
    private ds: DataService

  ) {   }
  ngOnInit(): void {
    //const component = this;

  }


  upcpartupdate(){
    console.log('snupdate or enter');
    const upc_part = $('#upc_part').val();
    $('#fi_sn').focus();

    this.ds.dsGet('search?q=' + upc_part, '{serial2:abc}').subscribe((data) => {

      const qrPN = JSON.parse(JSON.stringify(data)).items.partnumber;


      if (qrPN[0] !== undefined && qrPN[0].product_id !== undefined) {
        $('#pname').val(qrPN[0].product_name);
        $('#pnumber').val(qrPN[0].part_number);
        $('#pid').val(qrPN[0].product_id);
        $('#upc_part_msg').hide();
        $('#save').prop('disabled', false);

      } else {
        console.error('item part upc not found');
        //upc_part_msg
        $('#upc_part_msg').html('item part upc not found.').show();


        $('#pname').val('Not Found');
        $('#pnumber').val('Not Found');
        $('#pid').val('Not Found');
        $('#upc_part').focus().select();;
        $('#save').prop('disabled', true);

      }

    });


  }

  receiveItem(){

    // check tracking

    // check upc / part

    // check serial

    // check condition


    const fi_tn = $('#fi_tn').val();
    const item_condition = $('#item_condition').val();
    const upc_part = $('#upc_part').val();
    const fi_sn = $('#fi_sn').val();
    let ecount = 0;

    if (fi_tn.length < 5 ) {
      $('#fi_tn_msg').html('Please enter tracking number').show();
      ecount ++;
    }else{$('#fi_tn_msg').hide();}

    if (upc_part.length < 3 ) {
      $('#upc_part_msg').html('Please enter upc/part number').show();
      ecount ++;
    }else{$('#upc_part_msg').hide();}


    if (fi_sn.length <= 3 ) {
      $('#fi_sn_msg').html('Please enter serial number').show();
      ecount ++;
    }else{$('#fi_sn_msg').hide();}

    if (item_condition == 0 ) {
      $('#item_condition_msg').html('Please select condition of the item').show();
      ecount ++;
    }else{$('#item_condition_msg').hide();}

    if(ecount>0){
      alert('fix all '+ecount+' error(s).');
      return false;
    }

    const fdata = {};
    $('#f_shipinmanual').serializeArray().map(function (x) { fdata[x.name] = x.value; });

    console.log(fdata);


    console.log(JSON.stringify(fdata,null,'\t'));
    
    this.ds.dsPost('kiosk/receivemanual', fdata).subscribe((data) => {
      // const qrALL = JSON.parse(JSON.stringify(data)).items;

      
    });    
 
    $("#fi_sn").val('');
    $("#f_shipinmanual").trigger("reset");
    alert('received');


  }

}
