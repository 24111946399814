import {Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';


@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss']
})
export class ItemComponent implements OnInit {

  dItems: any;
  constructor(private ds: DataService) {}

  ngOnInit(): void {


    this.ds.dsGet('products',{}).subscribe((data)=>{

      // this.dItems = data.items;
      this.dItems = JSON.parse(JSON.stringify(data)).items;


    });


  }

}


