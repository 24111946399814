<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Create Purchase Order</h1>
            </div>
            <div class="col-sm-6">

                
            </div>
        </div>
    </div>
</section>


<section class="content"><div class="row"><div class="col-md-12"><div class="box box-info ">




    

    <div class="row">
        <div class="col-md-12">
    
    
    
          <form id="form_new_po">
            <table class="table table-hover table-bordered" style="width:100%">
              <tr>
                  <th class="bg-gray ">PO Number</th>
                  <td><input class="form-control" type="text" value="00000" disabled></td>
                </tr>
                <tr>
                  <th class="bg-gray ">Created By</th>
                  <td><input class="form-control" type="text" value="Engin" disabled></td>
                </tr>                      
                  <tr>
                  <th class="bg-gray ">Vendor</th>
                  <td>
                      <select name="vendor_id" class="form-control select2"  style="width: 100%;">
                          <option value="0" selected>Select Vendor2</option>
                          <option *ngFor="let v of vendorlist" [value]="v.vendor_id" >{{ v.vendor_name }}</option>
                      </select>
                      

                  </td>
                </tr>

                
                <tr>
                  <th class="bg-gray ">PO Date</th>
                  <td>

                    <div class="input-group date">
                      <div class="input-group-addon">
                        <i class="fa fa-calendar"></i>
                      </div>
                      <input type="text" name="date" class="form-control pull-right datepicker" autocomplete="off">
                    </div>

                  </td>
                </tr>
                <tr>
                  <th class="bg-gray ">Reference</th>
                  <td><input class="form-control" type="text" name="reference" ></td>
                </tr>                      

                <tr>
                  <th class="bg-gray ">Note</th>
                  <td><textarea name="note" class="form-control" ></textarea></td>
                </tr>  
                          
           </table>  
           <div class="tar">
              <button class="btn btn-danger " [routerLink]="['/app/po']" routerLinkActive="router-link-active" > Cancel </button> 
              <button class="btn btn-success " (click)="create('form_new_po');"> Create Purchase Order</button>
          </div>   


      </form>
    
   

            
        </div>
    </div>
    






    









</div></div></div></section>