<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Shipments</h1>
            </div>
            <div class="col-sm-6">

            </div>
        </div>
    </div>
  </section>
  
  
  <section class="content">
  
    <div class="row">
  
        <div class="col-md-12">
            <div class="box box-info ">
            <table id="example1" class="table table-bordered table-striped">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Date</th>
                        <th>Tracking Number</th>
                        <th>Carrier</th>
                        <th>Status</th>
                        <th>Related PO</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
  
                    <tr *ngFor="let si of shipmentList">
                        <td><a routerLink="/app/shipping/detail/{{ si.shipment_id }}">{{ si.shipment_id}}</a></td>
  
                        <td>{{ si.shipment_date}}</td>
                        <td>{{ si.shipment_tracking_number}}</td>
                        <td>{{ si.carrier_name}}</td>
                        <td>{{ si.status_desc}}</td>
  
                        <td><a routerLink="/app/po/{{ si.po_id }}">{{ si.po_id}}</a></td>

                        <td>
                          <a routerLink="/app/shipping/in/{{ si.shipment_id }}"> 
                            <i class="fas fa-sign-in-alt"></i> Receive
                          </a> 
                           
                       
                        </td>
                    </tr>
                    
  
  
                </tbody>
            </table>
            </div>
        </div>
    </div>
  
  </section>