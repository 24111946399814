<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Items</h1>
            </div>
            <div class="col-sm-6">
                <a [routerLink]="['/app/items/add']" routerLinkActive="router-link-active" ><button type="button" class="btn btn-block btn-success btn-flat">Add Item</button></a>
            </div>
        </div>
    </div>
</section>


<section class="content">

    <div class="row">

        <div class="col-md-12">
            <div class="box box-info ">
            <table id="example1" class="table table-bordered table-striped">
                <thead>
                    <tr>
                        <th>Item #</th>
                        <th>Name</th>
                        <th>Part #</th>
                        <th>UPCs</th>
                        <th>Brand</th>
                        <th>Category</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>

                    <tr *ngFor="let item of dItems">
                        <td>{{ item.product_id}}</td>
                        <td width="20%">{{ item.product_name}}</td>
                        <td>{{ item.part_number}}</td>
                        <td>{{ item.tag}}</td>
                        <td>{{ item.brand_name}}</td>
                        <td>{{ item.category_name}}</td>
                        <td>
                            <div class="btn-group" title="View Account">
                                <a class="btn btn-primary btn-o dropdown-toggle" data-toggle="dropdown" href="#" aria-expanded="false">
                                    Action <span class="caret"></span>
                                </a>
                                <ul role="menu" class="dropdown-menu dropdown-light pull-right">
                                    <li>
                                        <a title="Update Record ?" routerLink="/app/items/edit/{{ item.product_id }}">
                                            <i class="fa fa-fw fa-edit text-blue"></i>Edit
                                        </a>
                                    </li>
                                    <li>
                                        <a title="Check Inventory" routerLink="/app/inventory/{{ item.product_id }}">
                                            <i class="fa fa-fw fa-eye text-blue"></i>Inventory
                                        </a>
                                    </li>

                                    <li>
                                        <a title="Check History" routerLink="/app/inventory/{{ item.product_id }}">
                                            <i class="fa fa-fw fa-eye text-blue"></i>History
                                        </a>
                                    </li>
 
                                    <li>
                                        <a title="Check Trends" routerLink="/app/inventory/{{ item.product_id }}">
                                            <i class="fa fa-fw fa-bar-chart  text-blue"></i>Trend
                                        </a>
                                    </li>

                                    <li>
                                        <a title="Check Open PO" routerLink="/app/inventory/{{ item.product_id }}">
                                            <i class="fa fa-fw fa-eye text-blue"></i>Open PO
                                        </a>
                                    </li>
                                    <li>
                                        <a title="Check Opem SO" routerLink="/app/inventory/{{ item.product_id }}">
                                            <i class="fa fa-fw fa-eye text-blue"></i>Opem SO
                                        </a>
                                    </li>                                                                        
                                </ul>
                            </div>
                        </td>
                   </tr>


                </tbody>
            </table>
            </div>
        </div>
    </div>

</section>