<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1 id="tt">Receive Shipment</h1>
            </div>
            <div class="col-sm-6">

                <a [routerLink]="['/app/shipping']" routerLinkActive="router-link-active"   class="btn btn-success btn-flat" ><i class="fas fa-angle-right"></i> All Shipping </a>
                
            </div>
        </div>
    </div>
</section>


<section class="content"><div class="row"><div class="col-md-12"><div class="box box-info ">




    

    <div class="row">
        <div class="col-md-12">
    
            <table class="table table-hover table-bordered" style="width:100%">
                <tr>
                  <th class="bg-gray ">Shipment Number</th>
                  <td><input class="form-control" type="text" value="{{sdata.shipment_id}}" disabled></td>
                </tr>

                <tr>
                  <th class="bg-gray ">Tracking Number</th>
                  <td><input class="form-control" type="text" value="{{sdata.shipment_tracking_number}}" disabled></td>
                </tr>

                <tr>
                  <th class="bg-gray ">Carrier Name</th>
                  <td><input class="form-control" type="text" value="{{sdata.carrier_name}}" disabled></td>
                </tr>  
              
           </table>  

    
            
        </div>
    </div>
    

    <div class="row">





        <div class="col-md-12">
            <div class="box box-info ">


                <div id="receive_order_line" class="modal">

                    <!-- Modal content -->
                    <div class="modal-content">


    
                  <table class="table table-hover table-bordered" style="width:100%">
                    <tr>
                        <th class="bg-gray ">Item</th>
                        <td><input class="form-control" type="text" value="Item Name" name="item_name" id="item_name" disabled ></td>
                      </tr>
                      <tr>
                        <th class="bg-gray ">Part Number</th>
                        <td><input class="form-control" type="text" value="Part Number" name="part_number" id="part_number" disabled ></td>
                      </tr>
                      <tr>
                        <th class="bg-gray ">Condition</th>
                        <td><input class="form-control" type="text" name="item_condition" id="item_condition" disabled></td>
                      </tr>
                      <tr>
                        <th class="bg-gray ">Quantity</th>
                        <td><input class="form-control" type="text" name="item_qty" id="item_qty" value="1"  disabled></td>
                      </tr>
                      <tr>
                        <th class="bg-gray ">Serial Number</th>
                        <td><input class="form-control" type="text" name="item_serial_number" id="item_serial_number"></td>
                      </tr>  
                      <tr>
                        <th class="bg-gray ">Receiving Note</th>
                        <td><textarea name="item_note" id="item_note" class="form-control" ></textarea></td>
                      </tr>  
                                
                 </table>  
    <input type="hidden" id="po_line_id_receive" value="0">
<div class="tar">
    <button class="btn btn-success " (click)="receive_line(p,'1');" > Receive Item </button> 
    <button class="btn btn-warning " (click)="modal_close('receive_order_line');" > Cancel </button>
</div>                        
                    </div>
    
                  </div>

            <table id="example1" class="table table-bordered table-striped">
                <thead>
                    <tr>
                        <th>PO Line #</th>
                        <th>PO #</th>
                        <th>Date</th>
                        <th>Reference</th>
                        <th>Vendor</th>
                        <th>Product</th>
                        <th>Part Number</th>
                        <th>Condition</th>
                        <th>Unit Qty</th>
                        <th>Received Qty</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>

                    <tr *ngFor="let p of pos">
                        <td>{{ p.po_line_id}}</td>
                        <td><a routerLink="/app/so/{{ p.so_id }}">{{ p.po_id}}</a></td>
                        <td>{{ p.po_date }}</td>
                        <td>{{ p.reference }}</td>
                        <td><a routerLink="/app/vendor/{{ p.vendor_id }}">{{ p.vendor_name}}</a></td>
                        <td><a routerLink="/app/items/{{ p.product_id }}">{{ p.product_name}}</a></td>
                        <td><a routerLink="/app/items/{{ p.product_id }}">{{ p.part_number}}</a></td>
                        <td>{{ p.condition_name }}</td>
                        <td>{{ p.unit_qty }}</td>
                        <td>{{ p.received_qty }}</td>
                        <td><button *ngIf="p.is_received == '0'" class="btn btn-success " (click)="receive_line(p,'0');" > Receive Item</button></td>
                    </tr>

                    



                </tbody>
            </table>
            </div>
        </div>
    </div>



</div></div></div></section>