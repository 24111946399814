import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { Router, ActivatedRoute } from '@angular/router';

declare var $;


@Component({
  selector: 'app-shipin',
  templateUrl: './shipin.component.html',
  styleUrls: ['./shipin.component.scss']
})
export class ShipinComponent implements OnInit {



  constructor(
    private ds: DataService,
    private router: Router,
    private route: ActivatedRoute
  ) { }



  sdata:any;
  pos:any;
    p;
  ngOnInit(): void {
    const pid = this.route.snapshot.params.id;
    // Get customer Detail
    this.ds.dsGet('shipment/'+pid,'{}').subscribe((data)=>{
      // this.sdata = data.items[0];

      this.sdata = JSON.parse(JSON.stringify(data)).items[0];

      
    });


    // Get customer SOs
    this.ds.dsGet('shipment/'+pid+'/po','{}').subscribe((data)=>{
      this.pos = JSON.parse(JSON.stringify(data)).items;
    });


  }

  modal_open(modalId){
    $('#'+modalId).addClass('show');
  }

  modal_close(modalId){
    $('#'+modalId).removeClass('show');
  }


  receive_line(p,step){

    if(step == 0){
      $('#item_name').val(p.product_name);
      $('#part_number').val(p.part_number);
      $('#item_condition').val(p.condition_name);
      $('#po_line_id_receive').val(p.po_line_id);
      $('#item_serial_number').val('');
      $('#item_note').val('');
      this.modal_open('receive_order_line');
    }


    if(step == 1){
      var fdata = {};
      fdata['po_line_id'] = $('#po_line_id_receive').val();
      fdata['serial_number'] = $('#item_serial_number').val();
      fdata['item_note'] = $('#item_note').val();

      this.ds.dsPost('shipment/in',fdata).subscribe((data)=>{
        this.pos = JSON.parse(JSON.stringify(data)).items;
        this.ngOnInit();
        this.modal_close('receive_order_line');
      });
    }

  }

}
