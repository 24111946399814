import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-vendor',
  templateUrl: './vendor.component.html',
  styleUrls: ['./vendor.component.scss']
})
export class VendorComponent implements OnInit {

  vendorlist;
  
  constructor(
    private ds: DataService
  ) { }

  ngOnInit(): void {
    this.ds.dsGet('vendor','{}').subscribe((data)=>{
      this.vendorlist = JSON.parse(JSON.stringify(data)).items;
    });

  }

}
