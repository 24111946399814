import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'app-recentshipment',
  templateUrl: './recentshipment.component.html',
  styleUrls: ['./recentshipment.component.scss']
})
export class RecentshipmentComponent implements OnInit {


  dItems: any;
  constructor(private ds: DataService) {}

  ngOnInit(): void {


    this.ds.dsGet('kiosk/recent?limit=100',{}).subscribe((data)=>{

      // this.dItems = data.items;
      this.dItems = JSON.parse(JSON.stringify(data)).items;


    });


  }
}
