<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Recent Shipment <button class="btn btn-success" (click)="ngOnInit();" id='btn_ok'> Refresh </button></h1>
            </div>
            <div class="col-sm-6">

            </div>
        </div>
    </div>
</section>


<section class="content">

    <div class="row">

        <div class="col-md-12">
            <div class="box box-info ">
            <table id="example1" class="table table-bordered table-striped">
                <thead>
                    <tr>
                        <th>Shipment Time</th>
                        <th>Tracking Number</th>
                        <th>User</th>
                        <th>Type</th>
                        <th>Brand</th>
                        <th>Product Name</th>
                        <th>Part Number</th>
                        <th>Serial Number</th>
                        <th>Condition</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of dItems">
                        <td>{{ item.created_at+'z'  | date:'y/MM/d, h:mm a'  }}</td>
                        <td>{{ item.shipment_tracking_number}}</td>
                        <td>{{ item.firstname}}</td>
                        <td>{{ item.type_desc}}</td>
                        <td>{{ item.brand_name}}</td>
                        <td><a routerLink="/app/inventory/{{ item.product_id }}" >{{ item.product_name }}</a></td>
                        <td>{{ item.part_number}}</td>
                        <td><a routerLink="/app/inventory/movement/{{ item.inventory_item_id }}" >{{ item.serial_number }}</a></td>
                        <td>{{ item.condition_name}}</td>
                    </tr>


                </tbody>
            </table>
            </div>
        </div>
    </div>

</section>