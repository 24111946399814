<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Create Sales Order</h1>
            </div>
            <div class="col-sm-6">

                
            </div>
        </div>
    </div>
</section>


<section class="content"><div class="row"><div class="col-md-12"><div class="box box-info ">




    

    <div class="row">
        <div class="col-md-12">
    
    
    
          <form id="form_new_so">
            <table class="table table-hover table-bordered" style="width:100%">
              <tr>
                  <th class="bg-gray ">SO Number</th>
                  <td><input class="form-control" type="text" value="00000" disabled></td>
                </tr>
                <tr>
                  <th class="bg-gray ">Created By</th>
                  <td><input class="form-control" type="text" value="Engin" disabled></td>
                </tr>                      
                  <tr>
                  <th class="bg-gray ">Customer</th>
                  <td>
                      <select name="customer_id" class="form-control">
                          <option value="0" selected>Select Customer</option>
                          <option *ngFor="let c of customerlist" [value]="c.customer_id" >{{ c.customer_name }}</option>
                      </select>
                      

                  </td>
                </tr>

                
                <tr>
                  <th class="bg-gray ">SO Date</th>
                  <td><input class="form-control" type="date" name="date" ></td>
                </tr>
                <tr>
                  <th class="bg-gray ">Reference</th>
                  <td><input class="form-control" type="text" name="reference" ></td>
                </tr>                      

                <tr>
                  <th class="bg-gray ">Note</th>
                  <td><textarea name="note" class="form-control" ></textarea></td>
                </tr>  
                          
           </table>  
           <div class="tar">
              <button class="btn btn-danger " [routerLink]="['/app/so']" routerLinkActive="router-link-active" > Cancel </button> 
              <button class="btn btn-success " (click)="create('form_new_so');"> Create Sales Order</button>
          </div>   


      </form>
    
    
            
        </div>
    </div>
    






    









</div></div></div></section>