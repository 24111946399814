import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SellernodeComponent } from './sellernode/sellernode.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ItemComponent } from './item/item.component';

import { NgxPermissionsGuard } from 'ngx-permissions';
import { ItemaddComponent } from './item/itemadd/itemadd.component';
import { InventoryComponent } from './inventory/inventory.component';
import { InventorydetailComponent } from './inventory/inventorydetail/inventorydetail.component';
import { InventorymovementComponent } from './inventory/inventorymovement/inventorymovement.component';
import { InventorymanageComponent } from './inventory/inventorymanage/inventorymanage.component';
import { InventorymovementdetailComponent } from './inventory/inventorymovementdetail/inventorymovementdetail.component';
import { CustomerComponent } from './customer/customer.component';
import { CustomeraddComponent } from './customer/customeradd/customeradd.component';
import { VendorComponent } from './vendor/vendor.component';
import { VendoraddComponent } from './vendor/vendoradd/vendoradd.component';
import { SoComponent } from './so/so.component';
import { SoaddComponent } from './so/soadd/soadd.component';
import { SodetailComponent } from './so/sodetail/sodetail.component';
import { PoComponent } from './po/po.component';
import { PoaddComponent } from './po/poadd/poadd.component';
import { PodetailComponent } from './po/podetail/podetail.component';
import { CustomerdetailComponent } from './customer/customerdetail/customerdetail.component';
import { VendordetailComponent } from './vendor/vendordetail/vendordetail.component';
import { ShipinComponent } from './shipping/shipin/shipin.component';
import { ShipoutComponent } from './shipping/shipout/shipout.component';
import { ShippingComponent } from './shipping/shipping.component';
import { ShippingdetailComponent } from './shipping/shippingdetail/shippingdetail.component';
import { UnauthorizedComponent } from './shared/unauthorized/unauthorized.component';
import { NotfoundComponent } from './shared/notfound/notfound.component';
import { KioskComponent } from './shipping/kiosk/kiosk.component';
import { RecentshipmentComponent } from './shipping/recentshipment/recentshipment.component';
import { InventorymanagedetailComponent } from './inventory/inventorymanagedetail/inventorymanagedetail.component';
import { InventorytestComponent } from './inventory/inventorytest/inventorytest.component';
import { ShipinmanualComponent } from './shipping/shipinmanual/shipinmanual.component';


const routes: Routes = [
  {
    path: '', component: SellernodeComponent,
      children: [
        {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
        {path: 'dashboard', component: DashboardComponent},
        // product 
        {
          path: 'items',
          component: ItemComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            permissions: {
              only: ['syrup','items'],
              redirectTo: '/app/401'
            }
          }
        },
        {
          path: 'items/add',
          component: ItemaddComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            permissions: {
              only: ['syrup','items'],
              redirectTo: '/app/401'
            }
          }
        },
        {
          path: 'items/edit/:id',
          component: ItemaddComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            permissions: {
              only: ['syrup','items'],
              redirectTo: '/app/401'
            }
          }
        },
        {
          path: 'inventory',
          component: InventoryComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            permissions: {
              only: ['syrup','inventory'],
              redirectTo: '/app/401'
            }
          }
        },
        {
          path: 'inventory/:id',
          component: InventorydetailComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            permissions: {
              only: ['syrup','inventory'],
              redirectTo: '/app/401'
            }
          }
        },
        {
          path: 'manage/inventory',
          component: InventorymanageComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            permissions: {
              only: ['syrup','inventory'],
              redirectTo: '/app/401'
            }
          }
        },
        {
          path: 'manage/inventory/:id',
          component: InventorymanagedetailComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            permissions: {
              only: ['syrup','inventory'],
              redirectTo: '/app/401'
            }
          }
        },
        {
          path: 'inventory/movement',
          component: InventorymovementComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            permissions: {
              only: ['syrup','inventory'],
              redirectTo: '/app/401'
            }
          }
        },
        {
          path: 'inventory/movement/:id',
          component: InventorymovementdetailComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            permissions: {
              only: ['syrup','inventory'],
              redirectTo: '/app/401'
            }
          }
        },
        {
          path: 'test/inventory',
          component: InventorytestComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            permissions: {
              only: ['syrup','inventory','test'],
              redirectTo: '/app/401'
            }
          }
        },
        {
          path: 'customer',
          component: CustomerComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            permissions: {
              only: ['syrup','customer'],
              redirectTo: '/app/401'
            }
          }
        },
        {
          path: 'customer/add',
          component: CustomeraddComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            permissions: {
              only: ['syrup','customer'],
              redirectTo: '/app/401'
            }
          }
        },
        {
          path: 'customer/edit/:id',
          component: CustomeraddComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            permissions: {
              only: ['syrup','customer'],
              redirectTo: '/app/401'
            }
          }
        },
        {
          path: 'customer/:id',
          component: CustomerdetailComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            permissions: {
              only: ['syrup','customer'],
              redirectTo: '/app/401'
            }
          }
        },
        {
          path: 'vendor',
          component: VendorComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            permissions: {
              only: ['syrup','vendor'],
              redirectTo: '/app/401'
            }
          }
        },
        {
          path: 'vendor/add',
          component: VendoraddComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            permissions: {
              only: ['syrup','vendor'],
              redirectTo: '/app/401'
            }
          }
        },
        {
          path: 'vendor/edit/:id',
          component: VendoraddComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            permissions: {
              only: ['syrup','vendor'],
              redirectTo: '/app/401'
            }
          }
        },
        {
          path: 'vendor/:id',
          component: VendordetailComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            permissions: {
              only: ['syrup','vendor'],
              redirectTo: '/app/401'
            }
          }
        },        {
          path: 'so',
          component: SoComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            permissions: {
              only: ['syrup','so'],
              redirectTo: '/app/401'
            }
          }
        },
        {
          path: 'so/add',
          component: SoaddComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            permissions: {
              only: ['syrup','so'],
              redirectTo: '/app/401'
            }
          }
        },
        {
          path: 'so/:id',
          component: SodetailComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            permissions: {
              only: ['syrup','so'],
              redirectTo: '/app/401'
            }
          }
        },
        {
          path: 'po',
          component: PoComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            permissions: {
              only: ['syrup','po'],
              redirectTo: '/app/401'
            }
          }
        },
        {
          path: 'po/add',
          component: PoaddComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            permissions: {
              only: ['syrup','po'],
              redirectTo: '/app/401'
            }
          }
        },
        {
          path: 'po/:id',
          component: PodetailComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            permissions: {
              only: ['syrup','po'],
              redirectTo: '/app/401'
            }
          }
        },
        {
          path: 'shipping',
          component: ShippingComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            permissions: {
              only: ['syrup','shipping'],
              redirectTo: '/app/401'
            }
          }
        },
        {
          path: 'shipping/detail/:id',
          component: ShippingdetailComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            permissions: {
              only: ['syrup','shipping'],
              redirectTo: '/app/401'
            }
          }
        },
        {
          path: 'shipping/manualin',
          component: ShipinmanualComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            permissions: {
              only: ['syrup','shipping'],
              redirectTo: '/app/401'
            }
          }
        },
        {
          path: 'shipping/in',
          component: ShipinComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            permissions: {
              only: ['syrup','shipping'],
              redirectTo: '/app/401'
            }
          }
        },        {
          path: 'shipping/in/:id',
          component: ShipinComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            permissions: {
              only: ['syrup','shipping'],
              redirectTo: '/app/401'
            }
          }
        },
        {
          path: 'shipping/out',
          component: ShipoutComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            permissions: {
              only: ['syrup','shipping'],
              redirectTo: '/app/401'
            }
          }
        },
        {
          path: 'shipping/out/:id',
          component: ShipoutComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            permissions: {
              only: ['syrup','shipping'],
              redirectTo: '/app/401'
            }
          }
        },
        {
          path: 'shipping/kiosk',
          component: KioskComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            permissions: {
              only: ['syrup','kiosk'],
              redirectTo: '/app/401'
            }
          }
        },
        {
          path: 'shipping/recent',
          component: RecentshipmentComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            permissions: {
              only: ['syrup','shipping', 'kiosk'],
              redirectTo: '/app/401'
            }
          }
        },
        {
          path: '401',
          component: UnauthorizedComponent,
        },
        {
          path: '404',
          component: NotfoundComponent,
        }

    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SellernodeRoutingModule {
}
