<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>{{ pdetail.brand_name }} - {{ pdetail.product_name }}</h1>
                <p>{{ pdetail.product_id }}, {{ pdetail.part_number }}, {{ pdetail.tag }}</p>
            </div>
            <div class="col-sm-6">
                <a routerLink="/app/inventory/{{ pdetail.product_id }}" [queryParams]="{cond: 'all'}"><button type="button" class="btn btn-success btn-flat">ALL</button></a>
                <a routerLink="/app/inventory/{{ pdetail.product_id }}" [queryParams]="{cond: 'nib'}"><button type="button" class="btn btn-success btn-flat">NIB</button></a>
                <a routerLink="/app/inventory/{{ pdetail.product_id }}" [queryParams]="{cond: 'nob'}" ><button type="button" class="btn btn-success btn-flat">NOB</button></a>
                <a routerLink="/app/inventory/{{ pdetail.product_id }}" [queryParams]="{cond: 'ref'}" ><button type="button" class="btn btn-success btn-flat">REF</button></a>
                <a routerLink="/app/inventory/{{ pdetail.product_id }}" [queryParams]="{cond: 'used'}" ><button type="button" class="btn btn-success btn-flat">USED</button></a>
                <a routerLink="/app/inventory/{{ pdetail.product_id }}" [queryParams]="{cond: 'test'}" ><button type="button" class="btn btn-success btn-flat">TEST</button></a>
                <a routerLink="/app/inventory/{{ pdetail.product_id }}" [queryParams]="{cond: 'part'}" ><button type="button" class="btn btn-success btn-flat">PART</button></a>
                <a routerLink="/app/inventory/{{ pdetail.product_id }}" [queryParams]="{cond: 'return'}" ><button type="button" class="btn btn-success btn-flat">RETURN</button></a>
            </div>
        </div>
    </div>
</section>


<section class="content">

    <div class="row">

        <div class="col-md-12">
            <div class="box box-info ">
            <table id="example1" class="table table-bordered table-striped">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Serial Number</th>
                        <th>Age</th>
                        <th>Condition</th>
                        <th *ngxPermissionsOnly="['syrup', 'po']">Price</th>
                        <th>Vendor</th>
                        <th>PO</th>
                        <th>Note</th>
                    </tr>
                </thead>
                <tbody>

                    <tr *ngFor="let ii of isummary">
                        <td>{{ ii.inventory_item_id}}</td>
                        <td><a routerLink="/app/inventory/movement/{{ ii.inventory_item_id }}" >{{ ii.serial_number}}</a></td>
                        <td>{{ ii.inventory_age}}</td>
                        <td>{{ ii.product_cond_name}}</td>
                        <th *ngxPermissionsOnly="['syrup', 'po']">{{ ii.unit_total }}</th>
                        <td><a routerLink="/app/vendor/{{ ii.vendor_id }}">{{ ii.vendor_name}}</a></td>
                        <td><a routerLink="/app/po/{{ ii.po_id }}">PO-{{ ii.po_id}}</a></td>
                        <td>{{ ii.note}}</td>
                    </tr>
                </tbody>
            </table>
            </div>
        </div>
    </div>

</section>