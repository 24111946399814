import { Injectable } from '@angular/core';
import { config } from 'src/app/config';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ApiRes } from '../models/apires';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private http: HttpClient) {}

  public dsGet(ep,gp) {
    return this.http.get<ApiRes>(`${config.apiUrl}/`+ep,gp)
  }

  public dsPost(ep,pp) {
      return this.http.post<ApiRes>(`${config.apiUrl}/`+ep,pp)
  }

  public getRandomNumber() {
    return this.http.get<any>(`${config.apiUrl}/api/random`).pipe(
      map(data => data.value )
      );
  }








}
