<section class="content-header">
  <div class="container-fluid">
      <div class="row mb-2">
          <div class="col-sm-6">
              <h1>Customers <button class="btn btn-success" (click)="ngOnInit();" id='btn_ok'> Refresh </button></h1>
          </div>
          <div class="col-sm-6">
              <a [routerLink]="['/app/customer/add']" routerLinkActive="router-link-active" ><button type="button" class="btn btn-success btn-flat">New Customer</button></a>
          </div>
      </div>
  </div>
</section>


<section class="content">

  <div class="row">

      <div class="col-md-12">
          <div class="box box-info ">
          <table id="example1" class="table table-bordered table-striped">
              <thead>
                  <tr>
                      <th>Customer #</th>
                      <th>Customer Name</th>
                      <th>Status</th>
                      <th>Note</th>
                      <th>Address</th>
                      <th>Created By</th>
                      <th>Actions</th>
                  </tr>
              </thead>
              <tbody>

                  <tr *ngFor="let c of customerlist">
                      <td><a routerLink="/app/customer/{{ c.customer_id }}">{{ c.customer_id}}</a></td>

                      <td>{{ c.customer_name}}</td>
                      <td>Draft</td>
                      <td>{{ c.note}}</td>

                      <td>{{ c.address}}</td>
                      <td>Engin</td>
                      <td>
                        <a routerLink="/app/customer/edit/{{ c.customer_id }}"> <i class="fa fa-fw fa-edit text-blue"></i>Edit
                      </a>
                      </td>
                  </tr>
                  


              </tbody>
          </table>
          </div>
      </div>
  </div>

</section>