<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1 id="tt">Create Customer</h1>
            </div>
            <div class="col-sm-6">

                
            </div>
        </div>
    </div>
</section>


<section class="content"><div class="row"><div class="col-md-12"><div class="box box-info ">




    

    <div class="row">
        <div class="col-md-12">
    
    
    
          <form id="form_new_customer">
            <table class="table table-hover table-bordered" style="width:100%">
                <tr>
                  <th class="bg-gray ">Customer Number</th>
                  <td><input class="form-control" type="text" value="{{cdata[0].customer_id}}" disabled></td>
                </tr>

                <tr>
                  <th class="bg-gray ">Created By</th>
                  <td><input class="form-control" type="text" name="created_by" value="Engin"  disabled></td>
                </tr>                      

                <tr>
                  <th class="bg-gray ">Customer Name</th>
                  <td><input class="form-control" type="text" name="customer_name" value="{{cdata[0].customer_name}}"></td>
                </tr>

                <tr>
                  <th class="bg-gray ">Note</th>
                  <td><textarea name="note" class="form-control" >{{cdata[0].note}}</textarea></td>
                </tr>  

                <tr>
                    <th class="bg-gray ">Address</th>
                    <td><textarea name="address" class="form-control" >{{cdata[0].address}}</textarea></td>
                </tr> 
                
           </table>  
           <div class="tar">
              <button class="btn btn-danger"  [routerLink]="['/app/customer']" routerLinkActive="router-link-active" > Cancel </button> 
              <button class="btn btn-success" (click)="create('form_new_customer');" id='btn_ok'> Create </button>
          </div>   

          <input class="form-control" type="hidden" name="customer_id"   value="{{cdata[0].customer_id}}" >
      </form>
    
    
            
        </div>
    </div>
    





</div></div></div></section>