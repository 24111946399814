<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Inventory Summary <button class="btn btn-success" (click)="ngOnInit();" id='btn_ok'> Refresh </button></h1>
            </div>
            <div class="col-sm-6">
                <a [routerLink]="['/app/purchase']" routerLinkActive="router-link-active" ><button type="button" class="btn btn-success btn-flat">Open PO</button></a>
                <a [routerLink]="['/app/sales']" routerLinkActive="router-link-active" ><button type="button" class="btn btn-success btn-flat">Open SO</button></a>
            </div>
        </div>
    </div>
</section>


<section class="content">

    <div class="row">

        <div class="col-md-12">
            <div class="box box-info ">
            <table id="example1" class="table table-bordered table-striped">
                <thead>
                    <tr>
                        <th>Item #</th>
                        <th>Name</th>
                        <th>Part #</th>
                        <th>NIB</th>
                        <th>NOB</th>
                        <th>REF</th>
                        <th>USED</th>
                        <th>TEST</th>
                        <th>PART</th>
                        <th>RETURN</th>
                    </tr>
                </thead>
                <tbody>

                    <tr *ngFor="let ii of isummary">
                        <td><a routerLink="/app/inventory/{{ ii.product_id }}" [queryParams]="{cond: 'all'}">{{ ii.product_id}}</a></td>
                        <td>{{ ii.product_name}}</td>
                        <td>{{ ii.part_number}}</td>
                        <td><a routerLink="/app/inventory/{{ ii.product_id }}" [queryParams]="{cond: 'nib'}">{{ ii.qty_nib}}</a></td>
                        <td><a routerLink="/app/inventory/{{ ii.product_id }}" [queryParams]="{cond: 'nob'}">{{ ii.qty_nob}}</a></td>
                        <td><a routerLink="/app/inventory/{{ ii.product_id }}" [queryParams]="{cond: 'ref'}">{{ ii.qty_ref}}</a></td>
                        <td><a routerLink="/app/inventory/{{ ii.product_id }}" [queryParams]="{cond: 'used'}">{{ ii.qty_used}}</a></td>
                        <td><a routerLink="/app/inventory/{{ ii.product_id }}" [queryParams]="{cond: 'test'}">{{ ii.qty_test}}</a></td>
                        <td><a routerLink="/app/inventory/{{ ii.product_id }}" [queryParams]="{cond: 'part'}">{{ ii.qty_part}}</a></td>
                        <td><a routerLink="/app/inventory/{{ ii.product_id }}" [queryParams]="{cond: 'return'}">{{ ii.qty_return}}</a></td>
                    </tr>

                </tbody>
            </table>
            </div>
        </div>
    </div>

</section>