<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Vendors</h1>
            </div>
            <div class="col-sm-6">
                <a [routerLink]="['/app/vendor/add']" routerLinkActive="router-link-active" ><button type="button" class="btn btn-success btn-flat">New Vendor</button></a>
            </div>
        </div>
    </div>
  </section>
  
  
  <section class="content">
  
    <div class="row">
  
        <div class="col-md-12">
            <div class="box box-info ">
            <table id="example1" class="table table-bordered table-striped">
                <thead>
                    <tr>
                        <th>Vendor #</th>
                        <th>Vendor Name</th>
                        <th>Status</th>
                        <th>Note</th>
                        <th>Address</th>
                        <th>Created By</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
  
                    <tr *ngFor="let v of vendorlist">
                        <td><a routerLink="/app/vendor/{{ v.vendor_id }}">{{ v.vendor_id}}</a></td>
  
                        <td>{{ v.vendor_name}}</td>
                        <td>Draft</td>
                        <td>{{ v.note}}</td>
  
                        <td>{{ v.address}}</td>
                        <td>Engin</td>
                        <td>
                          <a routerLink="/app/vendor/edit/{{ v.vendor_id }}"> 
                            <i class="fa fa-fw fa-edit text-blue"></i>Edit
                          </a>
                        </td>
                    </tr>
                    

  
                </tbody>
            </table>
            </div>
        </div>
    </div>
  
  </section>