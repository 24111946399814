import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../../services/data.service';

declare var $;

@Component({
  selector: 'app-kiosk',
  templateUrl: './kiosk.component.html',
  styleUrls: ['./kiosk.component.scss']
})
export class KioskComponent implements OnInit {

  sonenter = 'SE';
  teststop = 1;
  settn = '';
  setsc = '';
  setUser = 'initial value';
  setDirection = 'na';
  setSerial = '';

  p: any;

  recentItems: any;

  constructor(
    private router: Router,
    private ds: DataService
  ) { }




  isValidBarcode(barcode) {
    // check length
    if (barcode.length < 8 || barcode.length > 18 ||
      (barcode.length != 8 && barcode.length != 12 &&
        barcode.length != 13 && barcode.length != 14 &&
        barcode.length != 18)) {
      return false;
    }

    var lastDigit = Number(barcode.substring(barcode.length - 1));
    var checkSum = 0;
    if (isNaN(lastDigit)) { return false; } // not a valid upc/ean

    var arr = barcode.substring(0, barcode.length - 1).split("").reverse();
    var oddTotal = 0, evenTotal = 0;

    for (var i = 0; i < arr.length; i++) {
      if (isNaN(arr[i])) { return false; } // can't be a valid upc/ean we're checking for

      if (i % 2 == 0) { oddTotal += Number(arr[i]) * 3; }
      else { evenTotal += Number(arr[i]); }
    }
    checkSum = (10 - ((evenTotal + oddTotal) % 10)) % 10;

    // true if they are equal
    return checkSum == lastDigit;
  }

    getrecent(){
      this.ds.dsGet('kiosk/recent',{}).subscribe((data)=>{

        // this.dItems = data.items;
        this.recentItems = JSON.parse(JSON.stringify(data)).items;


      });
    }

  ngOnInit(): void {

    const component = this;

    this.getrecent();

    setInterval(function () {
      const currentTime = new Date();
      const currentHours = currentTime.getHours();
      const currentMinutes = currentTime.getMinutes();
      const currentSeconds = currentTime.getSeconds();

      $('.clock').html(
        (currentHours < 10 ? '0' : '') + currentHours + ':' +
        (currentMinutes < 10 ? '0' : '') + currentMinutes + ':' +
        (currentSeconds < 10 ? '0' : '') + currentSeconds);
    }, 1000)

    $('.cct li').click(function () {
      $('#input_kiosk').val($(this).html());
      var e = $.Event("keypress", { which: 13 });
      $('#input_kiosk').trigger(e);

    });


    $('#input_kiosk').val('');

    setInterval(function () {
      $('#input_kiosk').focus();
    })


    $('#input_kiosk').keypress(function (e) {
      const inputVal = $('#input_kiosk').val();
      this.sonenter = inputVal;
      const key = e.which;


      const regexUps1 = /\b(1Z ?[0-9A-Z]{3} ?[0-9A-Z]{3} ?[0-9A-Z]{2} ?[0-9A-Z]{4} ?[0-9A-Z]{3} ?[0-9A-Z]|[\dT]\d\d\d ?\d\d\d\d ?\d\d\d)\b/;
      const regexUps2 = /^[kKJj]{1}[0-9]{10}$/;

      const regexUsps0 = /(\b\d{30}\b)|(\b91\d+\b)|(\b\d{20}\b)/;
      const regexUsps1 = /(\b\d{30}\b)|(\b91\d+\b)|(\b\d{20}\b)|(\b\d{26}\b)| ^E\D{1}\d{9}\D{2}$|^9\d{15,21}$| ^91[0-9]+$| ^[A-Za-z]{2}[0-9]+US$/i;
      const regexUsps2 = /^E\D{1}\d{9}\D{2}$|^9\d{15,21}$/;
      const regexUsps3 = /^91[0-9]+$/;
      const regexUsps4 = /^[A-Za-z]{2}[0-9]+US$/;
      const regexUsps5 = /(\b\d{30}\b)|(\b91\d+\b)|(\b\d{20}\b)|(\b\d{26}\b)| ^E\D{1}\d{9}\D{2}$|^9\d{15,21}$| ^91[0-9]+$| ^[A-Za-z]{2}[0-9]+US$/i;
      const regexUsps6 = /(\b\d{30}\b)|(\b91\d+\b)|(\b\d{20}\b)|(\b\d{26}\b)|^E\D{1}\d{9}\D{2}$|^9\d{15,21}$|^91[0-9]+$|^[A-Za-z]{2}[0-9]+US$/i;

      const regexFedex1 = /(\b96\d{20}\b)|(\b\d{15}\b)|(\b\d{12}\b)/;
      const regexFedex2 = /\b((98\d\d\d\d\d?\d\d\d\d|98\d\d) ?\d\d\d\d ?\d\d\d\d( ?\d\d\d)?)\b/;
      const regexFedex3 = /^[0-9]{15}$/;

      const regexFedex = /^(((96|98)\d{5}\s?\d{4}$|^(96|98)\d{2})\s?\d{4}\s?\d{4}(\s?\d{3})?)$/i;
      const regexUps = /^(1Z\s?[0-9A-Z]{3}\s?[0-9A-Z]{3}\s?[0-9A-Z]{2}\s?[0-9A-Z]{4}\s?[0-9A-Z]{3}\s?[0-9A-Z]$|[\dT]\d{3}\s?\d{4}s?\d{3})$/i;
      const regexUsps = /^(EA|EC|CP|RA)\d{9}(\D{2})?$|^(7\d|03|23|91)\d{2}\s?\d{4}\s?\d{4}\s?\d{4}\s?\d{4}(\s\d{2})?$|^82\s?\d{3}\s?\d{3}\s?\d{2}$/i;

      const regexDhl = /^[0-9]{10,11}$/;

      const regexEmail = /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
      const regexset = /snset(.*)/;

      const regexNote = /note[0-9]{3}/;
      const regexCond = /CONDITION-(.*)/;

      const regexBarcode = /^(?=.*0)[0-9]{12}$/gm;


      const regexF = /\b(((96\d\d|6\d)\d{3} ?\d{4}|96\d{2}|\d{4}) ?\d{4} ?\d{4}( ?\d{3})?)\b/i;
      const regexUs1 = /\b((420 ?\d{5} ?)?(91|92|93|94|01|03|04|70|23|13)\d{2} ?\d{4} ?\d{4} ?\d{4} ?\d{4}( ?\d{2,6})?)\b/i;
      const regexUs2 = /\b((M|P[A-Z]?|D[C-Z]|LK|E[A-C]|V[A-Z]|R[A-Z]|CP|CJ|LC|LJ) ?\d{3} ?\d{3} ?\d{3} ?[A-Z]?[A-Z]?)\b/i;
      const regexUs3 = /((\d{4})(\s?\d{4}){4}\s?\d{2})|((\d{2})(\s?\d{3}){2}\s?\d{2})|((\D{2})(\s?\d{3}){3}\s?\D{2})/ig;

      if (key === 13) {
        $('#cl').append('<li>' + inputVal + '</li>');
        this.teststop = 0;

        if (inputVal === 'shipout') {
          $('#kdir').html('OUT').addClass('bgg');
          $('#fi_direction').val('shipout');
          $('#knote_row').hide();



          $('#kn').html('Product Name').removeClass('bgg loading');
          $('#kpn').html('Part Number').removeClass('bgg loading');
          $('#kcond').html('Condition').removeClass('bgg loading');
          $('#ksn').html('Serial Number').removeClass('bgg loading');
          $('#knote').html('Receiving Note').removeClass('bgg loading');
        
        
          $('#fi_tn').val();
          $('#fi_pn').val();
          $('#fi_sn').val();
          $('#fi_condition').val();
          $('#fi_note').val();
          $('#input_kiosk').val();
          this.teststop = 1;
          this.setDirection = 'shipout';
        }

        if (inputVal === 'shipin') {
          $('#kdir').html('IN').addClass('bgg');
          $('#fi_direction').val('shipin');
          $('#knote_row').show();
          $('#kn').html('Product Name').removeClass('bgg loading');
          $('#kpn').html('Part Number').removeClass('bgg loading');
          $('#kcond').html('Condition').removeClass('bgg loading');
          $('#ksn').html('Serial Number').removeClass('bgg loading');
          $('#knote').html('Receiving Note').removeClass('bgg loading');
        
        
          $('#fi_tn').val();
          $('#fi_pn').val();
          $('#fi_sn').val();
          $('#fi_condition').val();
          $('#fi_note').val();
          $('#input_kiosk').val();

          this.teststop = 1;
          this.setDirection = 'shipin';

        }

        if (inputVal === 'formclear') {
          $('#kuser').html('User').removeClass('bgg');
          $('#kdir').html('NA').removeClass('bgg');

          $('#ktn').html('Tracking #').removeClass('bgg bgo');
          $('#kn').html('Product Name').removeClass('bgg bgo');
          $('#kpn').html('Part Number').removeClass('bgg bgo');
          $('#kcond').html('Condition').removeClass('bgg bgo');
          $('#ksn').html('Serial Number').removeClass('bgg bgo');
          $('#knote').html('Receiving Note').removeClass('bgg bgo');

          $('#fi_user').val();
          $('#fi_direction').val();

          $('#fi_tn').val();
          $('#fi_pn').val();
          $('#fi_sn').val();
          $('#fi_condition').val();
          $('#fi_note').val();
          $('#input_kiosk').val();

          this.teststop = 1;
        }




        if (inputVal === 'formok' && (this.setDirection === 'shipout' || this.setDirection === 'shipin')) {
          //  $( '#kuser').html('Start Scanning').removeClass('bgg');
          $('#kuser').addClass('loading');
          $('#kdir').addClass('loading');
          $('#ktn').addClass('loading');
          $('#kn').addClass('loading');
          $('#kpn').addClass('loading');
          $('#kcond').addClass('loading');
          $('#ksn').addClass('loading');
          $('#knote').addClass('loading');




          const fdata = {};
          $('#f_kiosk').serializeArray().map(function (x) { fdata[x.name] = x.value; });



          if (this.setDirection === 'shipout') {
            // ship out

            //check form input for shipout


            component.ds.dsPost('kiosk/ship', fdata).subscribe((data) => {
              const qrALL = JSON.parse(JSON.stringify(data)).items;
              console.log(qrALL);
              component.getrecent();
              $('#input_kiosk').val();

            });

          } else {
            // receive
            //check form input for shipin

            

            component.ds.dsPost('kiosk/receive', fdata).subscribe((data) => {
              const qrALL = JSON.parse(JSON.stringify(data)).items;
              console.log(qrALL);
              component.getrecent();
              $('#input_kiosk').val();


            });




          }



if(true){
  $('#kuser').removeClass('loading');
  $('#kdir').removeClass('loading');
  $('#ktn').html('Tracking #').removeClass('bgg loading');
  $('#kn').removeClass('bgg loading').addClass('bgo');;
  $('#kpn').removeClass('bgg loading').addClass('bgo');;
  $('#kcond').removeClass('bgg loading').addClass('bgo');
  $('#ksn').html('Serial Number').removeClass('bgg loading');
  $('#knote').html('Receiving Note').removeClass('bgg loading');


  $('#fi_tn').val();
  $('#fi_sn').val();
  $('#fi_note').val();

}


          this.teststop = 1;
        }








        if (regexNote.test(inputVal) && this.teststop === 0) {

          const notelist = {};
          notelist['note000'] = 'Receiving Note';
          notelist['note001'] = 'BOX - CRASH';
          notelist['note002'] = 'BOX - TEAR';
          notelist['note003'] = 'BOX - WET';
          notelist['note004'] = 'BOX - BROWN';
          notelist['note005'] = 'BOX - NOT ACCEPTABLE';

          notelist['note010'] = 'ITEM - DUST';
          notelist['note011'] = 'ITEM - MISSING PART';

          if (this.setDirection === 'shipin') {
            $('#knote').html(notelist[inputVal]).addClass('bgg');
            $('#knote').html(notelist[inputVal]).addClass('bgg');
            $('#fi_note').val(notelist[inputVal]);
          }



          $('#input_kiosk').val('');

          this.teststop = 1;
        }



        if (regexCond.test(inputVal) && this.teststop === 0) {


          const condlist = {};
          condlist['CONDITION-NIB'] = 'NIB';
          condlist['CONDITION-NOB'] = 'NOB';
          condlist['CONDITION-REF'] = 'REF';
          condlist['CONDITION-USED'] = 'USED';
          condlist['CONDITION-PART'] = 'PART';
          condlist['CONDITION-TEST'] = 'TEST';
          condlist['CONDITION-RETURN'] = 'RETURN';

          if (this.setDirection === 'shipin') {
            $('#kcond').html(condlist[inputVal]).addClass('bgg');
            $('#fi_condition').val(condlist[inputVal]);
          }


          $('#input_kiosk').val('');

          this.teststop = 1;
        }


        if (regexEmail.test(inputVal) && this.teststop === 0) {
          const ename = (inputVal.substring(0, inputVal.lastIndexOf('@'))).toUpperCase();

          $('#kuser').html(ename).addClass('bgg');
          $('#fi_user').val(inputVal);

          $('#input_kiosk').val('');

          this.teststop = 1;
        }


        if(component.isValidBarcode(inputVal) && this.teststop === 0 && this.setDirection === 'shipout') {
          this.teststop = 3;
        }

        if (regexBarcode.test(inputVal) && this.teststop === 0 && this.setDirection === 'shipin') {
          console.log('LINE#275');
          //check part number from upc

          const isUPCvalid = component.isValidBarcode(inputVal);
          if(isUPCvalid){ this.teststop = 2; }



//          this.teststop = 1;
        }


        if (regexF.test(inputVal) && this.teststop === 0) {
          $('#ktn').html(inputVal).addClass('bgg');
          $('#fi_tn').val(inputVal);

          $('#input_kiosk').val('');
          this.teststop = 1;
          this.setsc = 'FedEx';
          console.log('LINE#300');
        }

        if (regexFedex.test(inputVal) && this.teststop === 0) {
          $('#ktn').html(inputVal).addClass('bgg');
          $('#fi_tn').val(inputVal);

          $('#input_kiosk').val('');
          this.teststop = 1;
          this.setsc = 'FedEx';
          console.log('LINE#312');
        }
        if (regexUps.test(inputVal) && this.teststop === 0) {
          $('#ktn').html(inputVal).addClass('bgg');
          $('#fi_tn').val(inputVal);
          $('#input_kiosk').val('');
          this.teststop = 1;
          this.setsc = 'UPS';
          console.log('LINE#320');

        }
        if (regexUsps.test(inputVal) && this.teststop === 0) {
          $('#ktn').html(inputVal).addClass('bgg');
          $('#fi_tn').val(inputVal);
          $('#input_kiosk').val('');
          this.teststop = 1;
          this.setsc = 'USPS';
        }

        if (regexUs1.test(inputVal) && this.teststop === 0) {
          $('#ktn').html(inputVal).addClass('bgg');
          $('#fi_tn').val(inputVal);
          $('#input_kiosk').val('');
          this.teststop = 1;
          this.setsc = 'USPS';
        }
        if (regexUs2.test(inputVal) && this.teststop === 0) {
          $('#ktn').html(inputVal).addClass('bgg');
          $('#fi_tn').val(inputVal);
          $('#input_kiosk').val('');
          this.teststop = 1;
          this.setsc = 'USPS';
        }
        if (regexUs3.test(inputVal) && this.teststop === 0) {
          $('#ktn').html(inputVal).addClass('bgg');
          $('#fi_tn').val(inputVal);
          $('#input_kiosk').val('');
          this.teststop = 1;
          this.setsc = 'USPS';
        }
        if (regexFedex1.test(inputVal) && this.teststop === 0) {
          $('#ktn').html(inputVal).addClass('bgg');
          $('#fi_tn').val(inputVal);
          $('#input_kiosk').val('');
          this.teststop = 1;
          this.setsc = 'FedEx';
          console.log('LINE#360');

        }
        if (regexFedex2.test(inputVal) && this.teststop === 0) {
          $('#ktn').html(inputVal).addClass('bgg');
          $('#fi_tn').val(inputVal);
          $('#input_kiosk').val('');
          this.teststop = 1;
          this.setsc = 'FedEx';
          console.log('LINE#366');

        }
        if (regexFedex3.test(inputVal) && this.teststop === 0) {
          $('#ktn').html(inputVal).addClass('bgg');
          $('#fi_tn').val(inputVal);
          $('#input_kiosk').val('');
          this.teststop = 1;
          this.setsc = 'FedEx';
          console.log('LINE#370');

        }

        if (regexUps1.test(inputVal) && this.teststop === 0) {
          $('#ktn').html(inputVal).addClass('bgg');
          $('#fi_tn').val(inputVal);
          $('#input_kiosk').val('');
          this.teststop = 1;
          this.setsc = 'UPS';
        }
        if (regexUps2.test(inputVal) && this.teststop === 0) {
          $('#ktn').html(inputVal).addClass('bgg');
          $('#fi_tn').val(inputVal);
          $('#input_kiosk').val('');
          this.teststop = 1;
          this.setsc = 'UPS';
        }

        if (regexUsps0.test(inputVal) && this.teststop === 0) {
          $('#ktn').html(inputVal).addClass('bgg');
          $('#fi_tn').val(inputVal);
          $('#input_kiosk').val('');
          this.teststop = 1;
          this.setsc = 'USPS';
        }
        if (regexUsps1.test(inputVal) && this.teststop === 0) {
          $('#ktn').html(inputVal).addClass('bgg');
          $('#fi_tn').val(inputVal);
          $('#input_kiosk').val('');
          this.teststop = 1;
          this.setsc = 'USPS';
        }
        if (regexUsps2.test(inputVal) && this.teststop === 0) {
          $('#ktn').html(inputVal).addClass('bgg');
          $('#fi_tn').val(inputVal);
          $('#input_kiosk').val('');
          this.teststop = 1;
          this.setsc = 'USPS';
        }
        if (regexUsps3.test(inputVal) && this.teststop === 0) {
          $('#ktn').html(inputVal).addClass('bgg');
          $('#fi_tn').val(inputVal);
          $('#input_kiosk').val('');
          this.teststop = 1;
          this.setsc = 'USPS';
        }
        if (regexUsps4.test(inputVal) && this.teststop === 0) {
          $('#ktn').html(inputVal).addClass('bgg');
          $('#fi_tn').val(inputVal);
          $('#input_kiosk').val('');
          this.teststop = 1;
          this.setsc = 'USPS';
        }
        if (regexUsps5.test(inputVal) && this.teststop === 0) {
          $('#ktn').html(inputVal).addClass('bgg');
          $('#fi_tn').val(inputVal);
          $('#input_kiosk').val('');
          this.teststop = 1;
          this.setsc = 'USPS';
        }
        if (regexUsps6.test(inputVal) && this.teststop === 0) {
          $('#ktn').html(inputVal).addClass('bgg');
          $('#fi_tn').val(inputVal);
          $('#input_kiosk').val('');
          this.teststop = 1;
          this.setsc = 'USPS';
        }



        if (regexDhl.test(inputVal) && this.teststop === 0) {
          $('#ktn').html(inputVal).addClass('bgg');
          $('#fi_tn').val(inputVal);
          $('#input_kiosk').val('');
          this.teststop = 1;
          this.setsc = 'DHL';
        }




        if (this.teststop === 0 && this.setDirection === 'shipout') {
          $('#ksn').addClass('loading').removeClass('bgg bgr');
          $('#kn').addClass('loading').removeClass('bgg bgr');
          $('#kpn').addClass('loading').removeClass('bgg bgr');
          $('#kcond').addClass('loading').removeClass('bgg bgr');

          $('#input_kiosk').val('');

          component.ds.dsGet('search?q=' + inputVal, '{serial2:abc}').subscribe((data) => {

            const qrN = JSON.parse(JSON.stringify(data)).items.product_name;
            const qrSN = JSON.parse(JSON.stringify(data)).items.serialnumber;
            const qrPN = JSON.parse(JSON.stringify(data)).items.partnumber;
            const qrALL = JSON.parse(JSON.stringify(data)).items;

            if (qrSN[0] !== undefined && qrSN[0].inventory_item_id !== undefined) {
              $('#ksn').html(qrSN[0].serial_number).addClass('bgg').removeClass('loading');
              $('#kn').html(qrSN[0].product_name).addClass('bgg').removeClass('loading');
              $('#kpn').html(qrSN[0].part_number).addClass('bgg').removeClass('loading');
              $('#kcond').html(qrSN[0].condition_desc).addClass('bgg').removeClass('loading');

              $('#fi_sn').val(qrSN[0].serial_number);
              $('#fi_pn').val(qrSN[0].part_number);
              $('#fi_condition').val(qrSN[0].condition_id);
            } else {
              $('#ksn').html(inputVal).addClass('bgr').removeClass('loading');
              if (qrPN[0] !== undefined) {
                $('#kn').html('Is this part number?').addClass('bgr').removeClass('loading');
              } else {
                $('#kn').html('Serial Not Found').addClass('bgr').removeClass('loading');
              }
              $('#kpn').html('Serial Not Found').addClass('bgr').removeClass('loading');
              $('#kcond').html('...').addClass('bgr').removeClass('loading');
            }

          });
        }

        if ((this.teststop === 0 || this.teststop === 2 )&& this.setDirection === 'shipin') {

          console.log('Line#500 ' + this.teststop);

          $('#kn').addClass('loading');
          $('#kpn').addClass('loading');
          $('#ksn').addClass('loading');

          $('#input_kiosk').val('');

          component.ds.dsGet('search?q=' + inputVal, '{serial2:abc}').subscribe((data) => {

            const qrN = JSON.parse(JSON.stringify(data)).items.product_name;
            const qrSN = JSON.parse(JSON.stringify(data)).items.serialnumber;
            const qrPN = JSON.parse(JSON.stringify(data)).items.partnumber;
            const qrALL = JSON.parse(JSON.stringify(data)).items;
            console.log('Line#510');
            console.log(qrPN);
            console.log(qrALL);
            if (qrPN[0] !== undefined && qrPN[0].part_number !== undefined) {
              //if part number exist 
              console.log('Line#515');

              $('#kn').html(qrPN[0].product_name).addClass('bgg').removeClass('loading bgr');
              $('#kpn').html(qrPN[0].part_number).addClass('bgg').removeClass('loading bgr');
              $('#ksn').removeClass('loading');

              $('#fi_pn').val(qrPN[0].part_number);

            } else {
              console.log('Line#520 '+ this.teststop);

              if( component.isValidBarcode(inputVal)) {
                console.log('Line#525');

                $('#kn').html('UPC Not Found').addClass('bgr').removeClass('loading bgg');
                $('#kpn').html(inputVal).addClass('bgr').removeClass('loading bgg');
                $('#ksn').removeClass('loading');

                this.teststop = 1;
              }else{
                console.log('Line#530');

              // if not exists // consider as serial number
              $('#ksn').html(inputVal).addClass('bgg').removeClass('loading');
              $('#kn').removeClass('loading');
              $('#kpn').removeClass('loading');
              $('#fi_sn').val(inputVal);

              }
            }


          });
          this.teststop = 1;
        }


        const fdata2 = {};
        $('#f_kiosk').serializeArray().map(function (x) { fdata2[x.name] = x.value; });
        console.log(fdata2);


        return false;
      }
    });







    if (this.teststop === 0) {
      console.log('LINE#498');

      $('#kdir').html('NA').addClass('bgr');
      $('#input_kiosk').val('Set Direction - Shipping or Receiving');


    }


  }

}
