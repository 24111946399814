<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Shipping In - Manual</h1>
            </div>
            <div class="col-sm-6">

            </div>
        </div>
    </div>
</section>
<section class="content">
    <div class="row">

        <div class="col-md-12">

            <div class="box box-info ">
                     
                <form  class="form" id="f_shipinmanual" accept-charset="utf-8">
                    <div class="box-body">
                     <div class="row">
                        <div class="form-group col-md-4">
                           <label for="fi_tn">Tracking Number</label>
                           <input type="text" class="form-control" name="fi_tn" id="fi_tn" >
                           <span id="fi_tn_msg" style="display:none" class="text-danger"></span>
                        </div>

                        <div class="form-group col-md-4">
                            <label for="fi_note">Receiving Note</label>
                            <input type="text" class="form-control" name="fi_note" id="fi_note">
                            <span id="fi_note_msg" style="display:none" class="text-danger"></span>
                         </div>

                     </div>
                     
                     <div class="row">
                        <div class="form-group col-md-4">
                            <label for="upc_part">UPC / Part Number</label>
                            <input type="text" class="form-control" name="upc_part" id="upc_part" (focusout)="upcpartupdate()" (keyup.enter)="upcpartupdate()">
                            <span id="upc_part_msg" style="display:none" class="text-danger"></span>
                         </div>

                         <div class="form-group col-md-4">
                            <label for="pname">Product Name</label>
                            <input type="text" class="form-control" name="pname" id="pname" readonly value="">
                         </div>  
 
                         <div class="form-group col-md-2">
                            <label for="pnumber">Part Number</label>
                            <input type="text" class="form-control" name="pnumber" id="pnumber" readonly value="">
                         </div>                         
                         <div class="form-group col-md-2">
                            <label for="pid">Product ID</label>
                            <input type="text" class="form-control" name="pid" id="pid" readonly value="">
                         </div> 

                           
                     </div>
                     
                     <div class="row">
                         <div class="form-group col-md-4">
                            <label for="fi_sn">Serial Number</label>
                            <input type="text" class="form-control" name="fi_sn" id="fi_sn">
                            <span id="fi_sn_msg" style="display:none" class="text-danger"></span>
                         </div>      

                         <div class="form-group col-md-4">
                            <label for="item_condition">Condition</label>

                            <select class="form-control" name="item_condition" id="item_condition">
                                <option value="0">Select</option>
                                <option value="1">NIB - New in Box</option>
                                <option value="2">NOB - New Open Box</option>
                                <option value="3">REF - Refurbished</option>
                                <option value="4">Used</option>
                                <option value="5">Test</option>
                                <option value="6">Part</option>
                                <option value="7">Return</option>
                            </select>
                            <span id="item_condition_msg" style="display:none" class="text-danger"></span>

                         </div>  
                        


                     </div>
                     <input type="hidden" id="fi_user" nam="fi_user" value="0" />

                     <!-- /.box-body -->
                     <div class="box-footer">
                        <div class="col-sm-8 col-sm-offset-2 text-center">
                           <!-- <div class="col-sm-4"></div> -->
                                                            <div class="col-md-3 col-md-offset-3">
                              <button type="button" id="save" class=" btn btn-block btn-success" title="shipItem" (click)="receiveItem();">Receive Now</button>
                           </div>
                           <div class="col-sm-3">
                              <button type="button" class="col-sm-3 btn btn-block btn-warning close_btn" title="Go Dashboard"  [routerLink]="['/app/items']" routerLinkActive="router-link-active">Close</button>
                           </div>
                        </div>
                     </div>
                     <!-- /.box-footer -->
                                    </div></form>
               <!-- /.box -->
            </div>


        </div>
    </div>
</section>