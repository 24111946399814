import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-so',
  templateUrl: './so.component.html',
  styleUrls: ['./so.component.scss']
})
export class SoComponent implements OnInit {
  
  sos;

  constructor(
    private ds: DataService
  ) { }

  ngOnInit(): void {
    this.ds.dsGet('so',{}).subscribe((data)=>{ 
      this.sos = JSON.parse(JSON.stringify(data)).items;
    });
  }

}
