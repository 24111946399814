import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { Router, ActivatedRoute } from '@angular/router';

declare var $;

@Component({
  selector: 'app-inventorydetail',
  templateUrl: './inventorydetail.component.html',
  styleUrls: ['./inventorydetail.component.scss']
})
export class InventorydetailComponent implements OnInit {
  isummary;
  pid;
  pdetail;
  constructor(
    private ds: DataService,
    private router: Router,
    private route: ActivatedRoute
    
  ) { }

  ngOnInit(): void {
    this.pid = this.route.snapshot.params.id;





    this.ds.dsGet('inventory/'+this.pid,this.route.snapshot.queryParamMap).subscribe((data)=>{
      this.isummary = JSON.parse(JSON.stringify(data)).items;
    });

    this.ds.dsGet('product/'+this.pid,this.route.snapshot.queryParamMap).subscribe((data)=>{
      this.pdetail = JSON.parse(JSON.stringify(data)).hdr;
    });

  }

}
